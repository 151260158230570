import React, { useEffect, useState } from 'react';
import MentorFeedback from './MentorFeedback';
import MentorRatings from './MentorRatings';
import { useSelector } from 'react-redux';
import agent from '../../agent';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
} from '../ui/select';
import { dashboardFeedbackDropdownOptions } from '../../constants/otherConstants';
import TablePagination from '@material-ui/core/TablePagination';
import { DatePickerWithRange } from '../../common/components/DatePickerWithRange';
import moment from 'moment-timezone';
import { getISOstring } from '../../common/functions';
import MentorRatingsSkeleton from './loaders/MentorRatingsSkeleton';

const Dashboard = () => {
  const [feedback, setFeedback] = useState([]);
  const [feedbackType, setFeedbackType] = useState('Doubt Session');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(25);
  const [filterDays, setFilterDays] = useState({
    from: moment.tz('Asia/Kolkata').subtract(3, 'months').toDate(),
    to: moment.tz('Asia/Kolkata').toDate(),
  });
  const [ratingLoader, setRatingLoader] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);

  const [mentorActivityData, setMentorActivityData] = useState({});

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        setFeedbackLoader(true);
        const response = await agent.feedback.getFeedback(
          `?type=${feedbackType}&page=${page}&rowsPerPage=${rowsPerPage}&startDate=${filterDays.from}&endDate=${filterDays.to}`
        );

        setFeedback(response?.data?.data?.rows);
        setTotalCount(response?.data?.data?.count);
      } catch (error) {
        console.error('Error fetching feedback:', error);
      } finally {
        setFeedbackLoader(false);
      }
    };
    fetchFeedback();
  }, [feedbackType, page, rowsPerPage, filterDays]);

  useEffect(() => {
    (async () => {
      try {
        setRatingLoader(true);
        const mentorActivity = await agent.feedback.getMentorRatings(
          `?startDate=${filterDays.from}&endDate=${filterDays.to}`
        );

        setMentorActivityData(mentorActivity?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setRatingLoader(false);
      }
    })();
  }, [filterDays]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="p-8 bg-gray-50 font-poppins">
      <div className="flex justify-end items-end">
        <DatePickerWithRange
          onDateRangeChange={(range) => setFilterDays(range)}
          initialDays={filterDays}
        />
      </div>
      {ratingLoader ? (
        <MentorRatingsSkeleton />
      ) : (
        <MentorRatings mentorActivityData={mentorActivityData} />
      )}
      <div className="flex justify-center items-center mt-20">
        <h1 className="text-3xl font-poppins">Your Overall Feedbacks</h1>
      </div>
      <div className="mt-5 flex flex-col md:flex-row justify-between items-center w-full">
        {/* Feedback Type Selector */}
        <div>
          <Select onValueChange={(value) => setFeedbackType(value)}>
            <SelectTrigger className="w-[250px]">
              <SelectValue placeholder={feedbackType} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Feedback Type</SelectLabel>
                {Object.values(dashboardFeedbackDropdownOptions).map(
                  (option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  )
                )}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {/* Pagination Component */}
        <div>
          <TablePagination
            component="div"
            page={page}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            labelRowsPerPage="Rows per page"
          />
        </div>
      </div>

      <div className="px-2">
        <MentorFeedback feedback={feedback} feedbackType={feedbackType} />
      </div>
    </div>
  );
};

export default Dashboard;
