import React from 'react';
import Editor from '@monaco-editor/react';

const MonacoEditor = ({
  height = '500px',
  language = 'javascript',
  theme = 'vs-dark',
  defaultValue = '// Write your code here',
  onChange = () => {},
  options = {},
  value = '',
}) => {
  const handleEditorChange = (value) => {
    onChange(value);
  };

  return (
    <Editor
      height={height}
      language={language}
      theme={theme}
      value={value}
      style={{ width: '100%' }}
      onChange={handleEditorChange}
      options={options}
    />
  );
};

export default MonacoEditor;
