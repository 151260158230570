export function extractCodeToShowUser(input) {
  // Use a regular expression to match text between the !&! delimiters
  const regex = /!&!([\s\S]*?)!&!/g;
  const match = regex.exec(input);

  // If a match is found, return the encapsulated code, else return an empty string
  return match ? match[1] : input;
}

export function mergeCode(boilerplateCode, solutionCode) {
  const regex = /!&![\s\S]*?!&!/g;

  // Check if the delimiters exist
  if (regex.test(boilerplateCode)) {
    // Replace the content between the delimiters with the solutionCode
    return boilerplateCode.replace(regex, solutionCode);
  } else {
    // If no delimiters are found, append the solutionCode to the end
    return `${boilerplateCode.trim()}\n\n${solutionCode}`;
  }
}

export function parseTableData(input) {
  // Split the input into sections based on the delimiter (###)
  const sections = input.split('###');

  // Function to parse a single section
  function parseSection(section) {
    const rows = section.trim().split('\n'); // Split by new lines
    const headers = rows[0].split('|'); // Get the headers
    const data = rows.slice(1).map((row) => {
      return row.split('|').reduce((obj, value, index) => {
        obj[headers[index]] = value;
        return obj;
      }, {});
    });
    return { headers, data };
  }

  // Parse each section and return them as objects for different tables
  const tables = sections.map((section) => parseSection(section));
  return tables;
}

export const validateSQLInputFormat = (input) => {
  const sections = input?.split('###') || [];

  if (sections?.length < 2) {
    return {
      isValid: false,
      message:
        'Input should contain at least one section and the output section.',
    };
  }

  const isValidTableName = (name) => /^[A-Za-z\s]+$/.test(name.trim());

  const parseSection = (section) => {
    const rows = section.trim().split('\n');
    if (rows.length < 3) {
      return {
        isValid: false,
        message: `Section is incomplete. It should have a table name, headers, and data.`,
      };
    }

    const tableName = rows[0].trim();

    // Validate the table name
    if (!isValidTableName(tableName)) {
      return {
        isValid: false,
        message: `Invalid table name: "${tableName}". Table name should consist of alphabetic characters and spaces.`,
      };
    }

    const headers = rows[1].split('|');
    const dataRows = rows.slice(2);

    if (headers.length < 2) {
      return {
        isValid: false,
        message: `Section ${tableName} must have at least 2 columns in headers.`,
      };
    }

    for (let i = 0; i < dataRows.length; i++) {
      const row = dataRows[i].split('|');
      if (row.length !== headers.length) {
        return {
          isValid: false,
          message: `Mismatch between headers and row columns in section ${tableName}.`,
        };
      }
    }

    return { isValid: true };
  };

  // Validate each section except the last one (Output section)
  for (let i = 0; i < sections.length - 1; i++) {
    const section = sections[i].trim();
    const result = parseSection(section);
    if (!result.isValid) {
      return result;
    }
  }

  // Validate the Output section
  const outputSection = sections[sections.length - 1].trim();
  const outputRows = outputSection.split('\n');
  if (
    outputRows.length !== 2 ||
    outputRows[0]?.trim()?.replaceAll('\n', '') !== 'Output'
  ) {
    return {
      isValid: false,
      message:
        'The last section should be named "Output" and contain output headers.',
    };
  }

  const outputHeaders = outputRows[1].split('|');
  if (outputHeaders.length < 1) {
    return {
      isValid: false,
      message: 'The output section must contain at least 2 columns.',
    };
  }

  return { isValid: true };
};
