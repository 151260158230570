import moment from 'moment';
import { ERROR_MSG } from './otherConstants';
export const isMobileNumberValid = (mobileNo) => {
  if (!mobileNo) return false;

  mobileNo = mobileNo?.trim();

  const isOnlyDigits = /^\d+$/.test(mobileNo);

  return mobileNo?.length === 10 && isOnlyDigits;
};

export const isEmail = (str) => {
  return str?.includes('@');
};

export const trimAndLowercase = (str) => {
  if (!str) return str;

  str = str?.trim();

  if (isEmail(str)) {
    str = str?.toLowerCase();
  }

  return str;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getQueryStringByFilterOptions = (filterOptions) => {
  let optionString = '';
  Object.keys(filterOptions).forEach((k) => {
    if (filterOptions[k] != null) optionString += `${k}=${filterOptions[k]}&`;
  });
  optionString = optionString.substring(0, optionString.length - 1);
  if (optionString.length > 0) {
    optionString = `?${optionString}`;
  }
  return optionString;
};
export const getErrorMessage = (error) => {
  return error?.response?.data?.message || ERROR_MSG;
};

export const getUniqueTags = (values) => {
  const uniqueItems = new Set();

  return values.filter((item) => {
    const identifier = JSON.stringify(item);
    if (uniqueItems.has(identifier)) {
      return false;
    } else {
      uniqueItems.add(identifier);
      return true;
    }
  });
};

export const formatTime = (time) => {
  if (!time) return '';
  return moment(time, 'HH:mm').format('hh:mm A');
};

/**
 * This function takes in a list of course classes and a day, and returns the classes which match the given day.
 * If the list of course classes is empty, it will return an empty array.
 * @param {Array} courseClasses - The list of course classes
 * @param {String} day - The day to filter by
 * @returns {Array} - The filtered list of classes
 */
export const filterClassesByDay = (courseClasses, day) => {
  const filterClasses = courseClasses
    ?.map((values) => values?.classes?.filter((item) => item.day === day))
    .filter((filteredClasses) => filteredClasses?.length > 0);
  return filterClasses[0];
};

/**
 * Truncates a given text to a specified maximum length and appends '...' if the text is truncated.
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length to which the text should be truncated.
 * @return {string} The truncated text with '...' appended if the original text was truncated.
 */
export const truncateText = (text, maxLength = 0) => {
  if (!text) return '';

  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

export const calculateStartTime = (time, bufferMinutes) => {
  const timeWithBuffer = moment(time, 'HH:mm:ss')
    .add(bufferMinutes, 'minutes') // Add the buffer minutes
    .format('h:mm A'); // Format to 12-hour time with AM/PM

  return timeWithBuffer;
};

/**
 * If the number is whole (no decimal places), it will be formatted as a whole number (e.g. 100).
 * Otherwise, it will be formatted with two decimal places (e.g. 100.00).
 * @param {number} value - The number to format
 * @returns {string} The formatted string
 */
export const formatNumber = (value) => {
  if (value % 1 === 0) {
    return value.toFixed(0);
  }
  return value.toFixed(2);
};

export const getUserTrackTitle = (user) => {
  const title = user?.UserDetail?.CurrentTrackId
    ? user?.UserDetail?.currentTrack?.subTitle
      ? user?.UserDetail?.currentTrack?.subTitle
      : user?.UserDetail?.currentTrack?.title
    : null;
  return title;
};
