import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { MdDelete } from 'react-icons/md';
import {
  calculateStartTime,
  getErrorMessage,
} from '../../constants/helperFunctions';
import agent from '../../agent';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COURSES } from '../../constants/actionTypes';
import { formatTime } from '../../constants/helperFunctions';
import {
  classScheduleStatus,
  daysOfWeek,
  mentorsClassType,
} from '../../constants/otherConstants';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import moment from 'moment';

const CreateClassDialog = ({
  setCreateClassDialogOpen,
  createClassDialogOpen,
  course,
  classEditData,
  editingClass,
  setEditingClass,
  setClassEditData,
}) => {
  const courses = useSelector((state) => state.CoursesReducers.courses);
  const user = useSelector((state) => state.common.user);
  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState('');
  const [postponedTime, setPostponedTime] = useState('');
  const [bufferTime, setBufferTime] = useState(0);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [day, setDay] = useState(daysOfWeek[0]);
  const [classType, setClassType] = useState(mentorsClassType[2].name);
  const [scheduleStatus, setScheduleStatus] = useState(
    classScheduleStatus.ON_TIME
  );
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const assignmentRes = await agent.Assignments.getAllAssignments(
          `?CourseId=${course.id}`
        );
        setCourseAssignments(assignmentRes.data.data);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    })();
  }, [course.id]);

  useEffect(() => {
    if (editingClass) {
      setStartTime(classEditData.startTime);
      setPostponedTime(classEditData.postponedTime);
      setDay(classEditData.day);
      setClassType(classEditData.type);
      setScheduleStatus(classEditData.scheduleStatus);
      setSelectedAssignments(classEditData.assignments);
    }
  }, [editingClass, classEditData]);

  const handleAddAssignment = () => {
    if (!startTime) {
      toast.error('Please select a start time before adding assignments.');
      return;
    }
    if (!selectedAssignment) {
      toast.error('Please select an assignment.');
      return;
    }

    const newAssignment = {
      ...selectedAssignment,
      bufferTime,
      startTime,
      sections: [],
    };

    if (scheduleStatus === classScheduleStatus.POSTPONED && postponedTime) {
      newAssignment.postponedTime = postponedTime;
    }

    setSelectedAssignments([...selectedAssignments, newAssignment]);
    setSelectedAssignment(null);
    setBufferTime(0);
  };

  const handleDeleteAssignment = (assignmentId) => {
    setSelectedAssignments((prevAssignments) =>
      prevAssignments.filter((a) => a.id !== assignmentId)
    );
  };

  const handleSectionToggle = (assignmentId, section) => {
    setSelectedAssignments((prevAssignments) =>
      prevAssignments.map((assignment) => {
        if (assignment.id === assignmentId) {
          const isAlreadySelected = assignment.sections.some(
            (s) => s.id === section.id
          );
          if (isAlreadySelected) {
            // Remove the section
            return {
              ...assignment,
              sections: assignment.sections.filter((s) => s.id !== section.id),
            };
          } else {
            // Add the section
            return {
              ...assignment,
              sections: [...assignment.sections, section],
            };
          }
        }
        return assignment;
      })
    );
  };

  /**
   * Handles the change event for the schedule status select box.
   * When the user switches from POSTPONED to another status, the postponed time is reset.
   */

  const statusOnChangeHandler = (e) => {
    setScheduleStatus((prev) => {
      if (prev === classScheduleStatus.POSTPONED) {
        setPostponedTime('');
        setSelectedAssignments((prevAssignments) => {
          return prevAssignments.map((a) => {
            return {
              ...a,
              scheduleStatus: classScheduleStatus.ON_TIME,
              postponedTime: null,
            };
          });
        });
      }
      return e.target.value;
    });
  };

  // function for creating a new class
  const handleCreateClass = async () => {
    // checks : if startTime is not there
    if (!startTime) {
      toast.error('Please provide all required details.');
      return;
    }
    if (selectedAssignments.length <= 0) {
      toast.error('Please select an assignment.');
      return;
    }

    // checks: if postponed time is not greater than start time
    const today = moment().format('YYYY-MM-DD');
    if (
      postponedTime &&
      moment(`${today} ${postponedTime}`, 'YYYY-MM-DD HH:mm').isBefore(
        moment(`${today} ${startTime}`, 'YYYY-MM-DD HH:mm')
      )
    ) {
      return toast.error('Postponed time must be greater than the start time');
    }

    // checks : if the status is live and meeting link is not present

    const newSelectedAssignment = selectedAssignments.map((a) => {
      const data = {
        ...a,
        scheduleStatus,
      };
      if (classScheduleStatus.POSTPONED === scheduleStatus && postponedTime) {
        data.postponedTime = postponedTime;
      }

      return data;
    });

    const classData = {
      CourseId: course.id,
      startTime,
      MentorId: user?.id,
      scheduleStatus,
      type: classType,
      selectedAssignments: newSelectedAssignment,
      day,
    };

    // checks : if the mentor is editing a class
    if (editingClass) {
      classData.id = classEditData.id;
    }
    if (scheduleStatus === classScheduleStatus.POSTPONED && postponedTime) {
      classData.postponedTime = postponedTime;
    }

    setLoader(true);
    try {
      const res = await agent.Courses.addOrUpdateCourseClass(classData);

      if (editingClass) {
        const newCourses = courses?.map((c) => {
          if (c.id === course.id) {
            const newClasses = course.classes.map((cl) => {
              if (cl.id === classData.id) {
                return classData;
              }
              return cl;
            });
            return { ...course, classes: newClasses };
          }
          return c;
        });

        dispatch({ type: GET_COURSES, payload: newCourses });
        toast.success('Class updated successfully!');
      } else {
        const newCourses = courses?.map((c) => {
          if (c.id === course.id) {
            return {
              ...course,
              classes: [
                ...(course.classes || []),
                { ...classData, ...res.data.data },
              ],
            };
          }
          return c;
        });
        dispatch({ type: GET_COURSES, payload: newCourses });
        toast.success('Class created successfully!');
      }

      setClassEditData({});
      setEditingClass(false);
      setCreateClassDialogOpen(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoader(false);
      setStartTime('');
      setSelectedAssignments([]);
      setBufferTime(0);
      setDay(daysOfWeek[0]);
      setClassType(mentorsClassType[2].name);
      setScheduleStatus(classScheduleStatus.ON_TIME);
    }
  };

  const filteredCourseAssignments = courseAssignments.filter(
    (a) => !selectedAssignments?.some((assignment) => assignment.id === a.id)
  );

  return (
    <Dialog
      open={createClassDialogOpen}
      onOpenChange={setCreateClassDialogOpen}
    >
      <DialogContent className="sm:max-w-[900px] p-6">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Create Class
          </DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-2 gap-4">
          {/* Day Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Day
            </label>
            <select
              value={day}
              onChange={(e) => setDay(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="" disabled>
                Select a day
              </option>
              {[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ].map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
          </div>

          {/* Start Time and Buffer Time */}
          <div className="col-span-2 flex space-x-4 items-end">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Start Time
              </label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  // Update start time in all selected assignments
                  setSelectedAssignments((prevAssignments) =>
                    prevAssignments.map((assignment) => {
                      return { ...assignment, startTime: e.target.value };
                    })
                  );
                }}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700">
                Buffer Time (minutes)
              </label>
              <select
                value={bufferTime}
                onChange={(e) => setBufferTime(Number(e.target.value))}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                disabled={!selectedAssignments?.length}
              >
                {[0, 5, 10, 15, 20, 25, 30].map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Class Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Class Type
            </label>
            <select
              value={classType}
              onChange={(e) => setClassType(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {mentorsClassType.map((c, index) => (
                <option key={index} value={c.name}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>

          {/* Status */}
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              value={scheduleStatus}
              onChange={statusOnChangeHandler}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value={classScheduleStatus.ON_TIME}>On Time</option>
              <option value={classScheduleStatus.POSTPONED}>Postponed</option>
              <option value={classScheduleStatus.CANCELLED}>Cancelled</option>
            </select>
          </div>
          {scheduleStatus === classScheduleStatus.POSTPONED && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Postponed Time
              </label>
              <input
                value={postponedTime}
                onChange={(e) => {
                  setPostponedTime(e.target.value);
                  // Update postponed time in all selected assignments
                  setSelectedAssignments((prevAssignments) =>
                    prevAssignments.map((assignment) => {
                      return {
                        ...assignment,
                        postponedTime: e.target.value,
                        scheduleStatus,
                      };
                    })
                  );
                }}
                type="time"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          )}

          {/* Select Assignment */}
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Select Assignment
            </label>
            <div className="flex space-x-5">
              <select
                onChange={(e) => {
                  const assignment = courseAssignments.find(
                    (a) => a.id === Number(e.target.value)
                  );
                  setSelectedAssignment(assignment || null);
                }}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value={''}>Select an assignment</option>
                {filteredCourseAssignments.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={handleAddAssignment}
                className="mt-1 p-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none w-[30%]"
              >
                Add
              </button>
            </div>
          </div>

          {/* Assignment List with Section Selection */}
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Selected Assignments
            </label>
            <ul className="mt-1 max-h-[17rem] overflow-y-auto">
              {selectedAssignments?.map((assignment) => (
                <li
                  key={assignment.id}
                  className="p-4 border border-gray-300 rounded-md mb-4"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <strong>{assignment.name}</strong> (Starts at{' '}
                      {calculateStartTime(
                        assignment.scheduleStatus ===
                          classScheduleStatus.POSTPONED
                          ? assignment.postponedTime
                          : assignment.startTime,
                        assignment.bufferTime || 0
                      )}
                      )
                    </div>
                    <button
                      type="button"
                      onClick={() => handleDeleteAssignment(assignment.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <MdDelete size={20} />
                    </button>
                  </div>

                  {/* Section Selection */}
                  {assignment.AssignmentSections &&
                  assignment.AssignmentSections.length > 0 ? (
                    <div className="mt-3">
                      <span className="block text-sm font-medium text-gray-700 mb-2">
                        Select Sections:
                      </span>
                      <div className="flex flex-wrap gap-2">
                        {assignment.AssignmentSections.map((section) => (
                          <label
                            key={section.id}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="checkbox"
                              checked={assignment.sections.some(
                                (s) => s.id === section.id
                              )}
                              onChange={() =>
                                handleSectionToggle(assignment.id, section)
                              }
                            />
                            <span>{section.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 mt-3">
                      No sections available for this assignment.
                    </p>
                  )}

                  {/* Display Selected Sections */}
                  {assignment.sections.length > 0 && (
                    <div className="mt-3">
                      <span className="block text-sm font-medium text-gray-700 mb-2">
                        Selected Sections:
                      </span>
                      <ul className="list-disc list-inside">
                        {assignment.sections.map((section) => (
                          <li key={section.id}>{section.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex justify-end space-x-4 mt-6">
          <button
            type="button"
            onClick={() => setCreateClassDialogOpen(false)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow-sm hover:bg-gray-300"
          >
            Cancel
          </button>
          {loader ? (
            <button
              type="button"
              disabled
              className="px-10 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700"
            >
              <AiOutlineLoading3Quarters className="animate-spin text-xl" />
            </button>
          ) : (
            <button
              type="button"
              onClick={handleCreateClass}
              className="px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700"
            >
              {editingClass ? 'Update Class' : 'Create Class'}
            </button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateClassDialog;
