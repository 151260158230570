import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  mobileNumberStatus,
  USER_DETAILS,
  WEB_TOKEN,
} from '../../../constants/otherConstants';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { push } from 'connected-react-router';
import { Link, useHistory } from 'react-router-dom';
import agent from '../../../agent';
import Loader from '../../../common/components/Loader';
import { LOGIN, SET_USER } from '../../../constants/actionTypes';
import { logo } from '../../../constants/otherConstants';
import { store } from '../../../store';
import '../Auth.scss';
import Content from '../Content';
import { pathNames } from '../../../constants/navigationPath';
import { toast } from 'react-toastify';

const ResetPassword = (props) => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const webToken = window.localStorage.getItem(WEB_TOKEN);
    if (webToken) {
      history.push(pathNames.DASHBOARD);
    }

    if (props.match.params.token) {
      setToken(props.match.params.token);
    }
  }, []);

  const changePassword = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (user.password !== user.confirmPassword) {
      setError('Password does not match!!!');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      await agent.Auth.resetPassword({ token, password: user.password });
      setLoading(false);
      setError(null);
      toast.success('Password changed successfully!!!');
      store.dispatch(push(pathNames.LOGIN));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('Error occured. Please try again after some time');
      }
      setLoading(false);
    }
  };
  return (
    <>
      <div className="reset-password-page">
        <div className="form">
          <div className="left">
            <form onSubmit={changePassword}>
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="login">
                <div className="head-div">
                  <div className="head-div-left">
                    <p className="login-title">Reset Password</p>
                    <p className="sub-head">Don't worry we never leave you!</p>
                  </div>
                </div>
                <div className="input-div">
                  <div className="password-input">
                    <p className="password-head">Password</p>
                    <TextField
                      className="password-in w-80"
                      required
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        startAdornment: <GppGoodOutlinedIcon />,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{
                                height: '0px',
                                background: 'white',
                                width: '0px',
                                color: 'black',
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="password-input">
                    <p className="password-head">Confirm password</p>
                    <TextField
                      className="password-in w-80"
                      required
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        startAdornment: <GppGoodOutlinedIcon />,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{
                                height: '0px',
                                background: 'white',
                                width: '0px',
                                color: 'black',
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setUser({ ...user, confirmPassword: e.target.value })
                      }
                    />
                  </div>
                  {error && <p className="error f6 pa1"> {error}</p>}
                  {/* {showCaptcha && (
                    <div className="flex justify-center">
                      <ReCaptcha
                        style={{ height: '200px' }}
                        onSuccessFunc={(res) => setCaptchaVerified(res.success)}
                      />
                    </div>
                  )} */}
                  <div className="btn-div" style={{ padding: '10px 0' }}>
                    <button
                      className="new-btn auth-btn f5 mt2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        'Reset'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Content />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch({ type: LOGIN, payload: data }),
  setUser: (data) => dispatch({ type: SET_USER, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
