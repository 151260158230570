import React, { useEffect, useState } from 'react';
import CreateClassDialog from './CreateClassDialog';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';
import { getParameterByName } from '../../common/functions';
import CourseTimeNew from './CourseTime';
import agent from '../../agent';
import { GET_COURSES } from '../../constants/actionTypes';
import { courseTypes } from '../../constants/data';
import { Switch } from '../ui/switch';

const CreateCourse = ({ location: { search } }) => {
  const [createClassDialogOpen, setCreateClassDialogOpen] = useState(false);

  const [course, setCourse] = useState({
    staggeredAdmission: false,
    prerequisites: [],
  });
  const [courseDescription, setCourseDescription] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const courses = useSelector((state) => state.CoursesReducers.courses);
  const [editingClass, setEditingClass] = useState(false);
  const [classEditData, setClassEditData] = useState({});
  const [isLive, setIsLive] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let course = { prerequisites: [] };
    const courseId = getParameterByName('CourseId');
    if (courseId) {
      course = courses?.find((c) => c.id === Number(courseId));
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    if (course) {
      setCourse(course);
      setCourseDescription(course.description);
      const courseIds = new Set(course.prerequisites?.data?.map((c) => c.id));
      const availableCourses = courses?.filter((c) => !courseIds.has(c.id));
      setAvailableCourses(availableCourses);
    }
  }, [search, courses]);

  const dialogOpenHandeler = () => {
    setCreateClassDialogOpen(!createClassDialogOpen);
    setEditingClass(false);
    setClassEditData({});
  };

  const deleteCoursePrerequisite = (preCourse) => {
    if (isUpdate) {
      const data = { CourseId: course.id, prerequisiteId: preCourse };

      setLoading(true);
      agent.Courses.deleteCoursePrerequisite(data)
        .then(() => {
          const pre = course.prerequisites.filter(
            (c) => Number(c.id) !== Number(preCourse)
          );
          const newCourse = { ...course, prerequisites: pre };

          const newCourses = courses?.map((c) => {
            if (c.id === newCourse.id) {
              return newCourse;
            }
            return c;
          });
          const newData = {
            ...courses,
            data: newCourses,
          };
          dispatch({ type: GET_COURSES, payload: newData });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err, err.response);
        });
    } else {
      const pre = course.prerequisites.filter(
        (c) => Number(c.id) !== Number(preCourse)
      );

      const newCourse = { ...course, prerequisites: pre };
      setCourse(newCourse);
      const courseIds = new Set(newCourse.prerequisites.map((c) => c.id));
      const availableCourses = courses?.filter((c) => !courseIds.has(c.id));
      setAvailableCourses(availableCourses);
    }
  };

  return (
    <>
      {createClassDialogOpen && (
        <CreateClassDialog
          createClassDialogOpen={createClassDialogOpen}
          setCreateClassDialogOpen={dialogOpenHandeler}
          editingClass={editingClass}
          classEditData={classEditData}
          setEditingClass={setEditingClass}
          setClassEditData={setClassEditData}
          course={course}
        />
      )}

      {/* Course Details */}
      <div className="flex flex-col gap-3 px-5 pt-5 border-b">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-semibold">Course Details</h1>
          <Link to={pathNames.COURSES}>
            <button className="sharpener-gradient-background py-2 px-6 rounded-md text-white shadow-md hover:bg-red-600">
              Back to Courses
            </button>
          </Link>
        </div>
        <div className="flex flex-col">
          <h2 className="font-medium text-gray-700">Course Name</h2>
          <p className="font-light text-gray-500">{course.name}</p>
        </div>
        <div className="flex flex-col">
          <h2 className="font-medium text-gray-700">Course Description</h2>
          {courseDescription ? (
            <div
              className="font-light text-gray-500"
              dangerouslySetInnerHTML={{ __html: courseDescription }}
            />
          ) : (
            <p className="font-light text-gray-500">No description added</p>
          )}
        </div>
        <div className="flex justify-start items-center gap-2">
          <h2 className="font-medium text-gray-700">Course Pre-Requisites:</h2>
          <div className="font-light text-gray-500">
            {course.prerequisites?.length > 0 ? (
              course.prerequisites?.map((c) => (
                <div className="ba br4 ml1 mr2 pa2 flex" key={c.id}>
                  <p>{c.name}</p>
                  <button onClick={() => deleteCoursePrerequisite(c.id)}>
                    <DeleteForeverIcon
                      className="danger"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </button>
                </div>
              ))
            ) : (
              <p>No course added as pre-requisite</p>
            )}
          </div>
        </div>
        <div className="flex justify-start items-center gap-2">
          <h2 className="font-medium text-gray-700">Course Type:</h2>
          <p className="font-light text-gray-500">
            {courseTypes[course?.type] || 'No course type selected'}
          </p>
        </div>
        <div className="flex justify-start items-center gap-2">
          <h2 className="font-medium text-gray-700">
            Assignment To Show at one time :
          </h2>
          <p className="font-light text-gray-500">
            {course.assignmentThreshold}
          </p>
        </div>
        <div className="flex justify-between items-center ">
          <div className="flex justify-start items-center gap-2 mb-2">
            <h2 className="font-medium text-gray-700">
              Allow Staggered Admission:
            </h2>
            <p
              className={`font-bold ${
                course.staggeredAdmission ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {course.staggeredAdmission ? 'Enabled' : 'Disabled'}
            </p>
          </div>
          <div className="flex items-center space-x-2 justify-end p-4 text-base">
            <label>Live</label>
            <Switch
              id="archive-switch"
              checked={!isLive}
              onCheckedChange={(checked) => setIsLive(!checked)}
            />
            <label>Archive</label>
          </div>
        </div>
      </div>

      {/* Class Details */}
      <div className="px-5 pt-3 flex justify-between items-center">
        <h1 className="text-lg font-semibold">Class Timings</h1>
        <button
          className="sharpener-gradient-background py-2 px-6 rounded-md text-white shadow-md hover:bg-red-400"
          onClick={() => setCreateClassDialogOpen(true)}
        >
          Add New Class
        </button>
      </div>

      {/* Days Tabs */}
      <CourseTimeNew
        isLive={isLive}
        course={course}
        setClassEditData={setClassEditData}
        setEditingClass={setEditingClass}
        setCreateClassDialogOpen={setCreateClassDialogOpen}
      />
    </>
  );
};

export default CreateCourse;
