import { toast } from 'react-toastify';
import agent from '../../../agent';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getObjectFromQueryString } from '../../../common/functions';
import { ERROR_MSG, taskStatus } from '../../../constants/otherConstants';
import { Editor } from '@monaco-editor/react';
import { getErrorMessage } from '../../../constants/helperFunctions';

const AddInputOutput = () => {
  const [taskinputOutput, setTaskInputOutput] = useState({
    input: '',
    initialStyling: '',
    taskTestCaseId: null,
  });
  const location = useLocation();
  const [outputList, setOutputList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [newOutput, setNewOutput] = useState('');

  const { AssignmentTaskId, status } = getObjectFromQueryString(
    location.search
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await agent.Assignments.getCssTaskInputOutput(
          `?AssignmentTaskId=${AssignmentTaskId}`
        );

        const input = res?.data?.data?.input;
        const output = res?.data?.data?.output;

        if (input && output) {
          setIsUpdate(true);

          const parsedOutput = JSON.parse(output);
          setTaskInputOutput({
            initialStyling: parsedOutput?.initialStyling,
            input: JSON.parse(input),
            taskTestCaseId: res?.data?.data?.id,
          });

          setOutputList(parsedOutput?.solutions);
        } else {
          setIsUpdate(false);
        }
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    })();
  }, []);

  const addCssTaskInputOutput = async (e) => {
    e.preventDefault();

    if (!taskinputOutput.input || !taskinputOutput.initialStyling) {
      return toast.error(
        'Please fill all the fields and add at least one output'
      );
    }

    if (outputList.length === 0) {
      return toast.error('Please add at least one output');
    }

    const data = {
      input: taskinputOutput.input,
      output: {
        solutions: outputList,
        initialStyling: taskinputOutput.initialStyling,
      },
      AssignmentTaskId,
    };

    if (isUpdate) {
      data.taskTestCaseId = taskinputOutput.taskTestCaseId;
    }

    try {
      await agent.Assignments.addCssTaskInputOutput(data);
      toast.success(
        `Input and output ${isUpdate ? 'updated' : 'added'} successfully`
      );
    } catch (err) {
      toast.err(getErrorMessage(err));
    }
  };

  const handleAddOutput = () => {
    if (!newOutput.trim()) {
      return toast.error('Output cannot be empty');
    }
    setOutputList([...outputList, newOutput]);
    setNewOutput('');
  };

  const handleDeleteOutput = (index) => {
    const updatedList = outputList.filter((_, i) => i !== index);
    setOutputList(updatedList);
  };

  return (
    <form
      className="flex flex-col gap-10 w-full p-5"
      onSubmit={addCssTaskInputOutput}
    >
      <div className="grid grid-cols-2 gap-10 w-full">
        <div className=" w-full ">
          <label>Input (HTML)</label>
          <Editor
            height="200px"
            language="html"
            defaultLanguage="html"
            theme="vs-dark"
            value={taskinputOutput.input}
            onChange={(value) =>
              setTaskInputOutput({ ...taskinputOutput, input: value })
            }
            options={{ minimap: { enabled: false }, wordWrap: 'on' }}
          />
        </div>
        <div className="w-full">
          <label>Initial Styling (CSS)</label>
          <Editor
            height="200px"
            language="css"
            theme="vs-dark"
            defaultLanguage="css"
            value={taskinputOutput.initialStyling}
            onChange={(value) =>
              setTaskInputOutput({
                ...taskinputOutput,
                initialStyling: value,
              })
            }
            options={{ minimap: { enabled: false }, wordWrap: 'on' }}
          />
        </div>
        <div className="w-full">
          <label>Output (CSS)</label>
          <div className="flex gap-2 items-center">
            <Editor
              height="200px"
              language="css"
              defaultLanguage="css"
              theme="vs-dark"
              value={newOutput}
              onChange={(value) => setNewOutput(value)}
              options={{ minimap: { enabled: false }, wordWrap: 'on' }}
            />
            {status && status !== taskStatus.LIVE && (
              <button
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md text-sm "
                onClick={handleAddOutput}
              >
                Add
              </button>
            )}
          </div>

          {outputList.length > 0 && (
            <ul className="mt-4 flex flex-col gap-2 w-[50rem]">
              <p className=" my-2">Added Outputs</p>
              {outputList.map((output, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center p-2 bg-gray-100 border-b"
                >
                  <p>{output}</p>
                  {status && status !== taskStatus.LIVE && (
                    <button
                      className="text-red-500 hover:text-red-700 font-bold"
                      onClick={() => handleDeleteOutput(index)}
                    >
                      Delete
                    </button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div>
        {status !== taskStatus.LIVE && (
          <button
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md text-sm "
            type="submit"
          >
            {isUpdate ? 'Update Task Input/Output' : 'Add Task Input/Output'}
          </button>
        )}
      </div>
    </form>
  );
};

export default AddInputOutput;
