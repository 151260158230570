import React from 'react';
import { useSelector } from 'react-redux';
import { FiClock, FiXCircle, FiRepeat } from 'react-icons/fi';
import {
  AiFillStar,
  AiOutlineUsergroupAdd,
  AiOutlineNumber,
} from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { formatNumber } from '../../constants/helperFunctions';

const MetricCard = ({ icon, label, value, color }) => (
  <div className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow p-10 border border-gray-200 flex justify-start items-center">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${color}`}>{icon}</div>
      <div>
        <p className="text-base font-medium text-gray-500">{label}</p>
        <p className="text-3xl font-semibold text-gray-900 mt-1">{value}</p>
      </div>
    </div>
  </div>
);

const MentorRatings = ({ mentorActivityData }) => {
  const user = useSelector((state) => state.common.user);

  return (
    <div className="max-w-8xl">
      <div className="flex gap-1 mb-10 text-start justify-start items-center">
        <h1 className="text-3xl font-semibold text-gray-800">Hi,</h1>
        <h1 className="text-3xl text-gray-800">{user?.name}</h1>
      </div>

      {/* Class Metrics */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          Class Metrics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <MetricCard
            icon={<FiClock className="w-6 h-6 text-blue-500" />}
            label="Class On-Time Percentage"
            value={`${formatNumber(
              mentorActivityData?.classOnTimePercentage || 0
            )}%`}
            color="bg-blue-100"
          />
          <MetricCard
            icon={<RxCross1 className="w-6 h-6 text-red-500" />}
            label="Number Of Missed Classes"
            value={mentorActivityData?.numberOfNotJoinedClass || 0}
            color="bg-red-100"
          />
          <MetricCard
            icon={<AiOutlineNumber className="w-6 h-6 text-green-500" />}
            label="Number of Classes Taken"
            value={mentorActivityData?.totalClassTaken || 0}
            color="bg-green-100"
          />
          <MetricCard
            icon={<AiFillStar className="w-6 h-6 text-yellow-500" />}
            label="Doubt Class Overall Rating"
            value={mentorActivityData?.averageClassRating || 0}
            color="bg-yellow-100"
          />
          <MetricCard
            icon={<FiXCircle className="w-6 h-6 text-red-500" />}
            label="Total Cancelled Classes"
            value={mentorActivityData?.numberOfCancelledClass || 0}
            color="bg-red-100"
          />
          <MetricCard
            icon={<FiRepeat className="w-6 h-6 text-purple-500" />}
            label="Total Postponed Classes"
            value={mentorActivityData?.numberOfPostponedClass || 0}
            color="bg-purple-100"
          />
          <MetricCard
            icon={<AiOutlineUsergroupAdd className="w-6 h-6 text-indigo-500" />}
            label="Number of Students Helped"
            value={mentorActivityData?.uniqueUserJoinedClassCount || 0}
            color="bg-indigo-100"
          />
        </div>
      </div>

      {/* Mock Interview Metrics */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          Mock Interview Metrics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <MetricCard
            icon={<FiClock className="w-6 h-6 text-blue-500" />}
            label="Mock On-Time Percentage"
            value={`${formatNumber(
              mentorActivityData?.mockOnTimePercentage || 0
            )}%`}
            color="bg-blue-100"
          />
          <MetricCard
            icon={<AiOutlineNumber className="w-6 h-6 text-green-500" />}
            label="Total Number of Mocks Taken"
            value={mentorActivityData?.totalMocksTaken || 0}
            color="bg-green-100"
          />
          <MetricCard
            icon={<AiFillStar className="w-6 h-6 text-yellow-500" />}
            label="Mock Interview Overall Rating"
            value={formatNumber(
              mentorActivityData?.averageAppointmentRating || 0
            )}
            color="bg-yellow-100"
          />
        </div>
      </div>
    </div>
  );
};

export default MentorRatings;
