import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';

import Loader from '../../common/components/Loader';
import agent from '../../agent';
import CodeEditor from './CodeEditor';
import {
  availableLanguages,
  ERROR_MSG,
  taskStatus,
} from '../../constants/otherConstants';
import './Courses.scss';
import './Compiler.scss';
import { boilerPlateCodeDelimeter } from '../../constants/otherConstants';

const defaultLanguage = availableLanguages[0];

const CreateBoilerplateCode = (props) => {
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [state, setState] = useState({ code: `` });

  const findNextMissingBoilerPlateCode = (existingCodes) => {
    availableLanguages.every((l) => {
      const code = existingCodes.find((c) => c.languageId === l.id);

      if (!code) {
        setSelectedLanguage(l);
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (props.task && props.task !== task) {
      setTask(props.task);
      findNextMissingBoilerPlateCode(props.task.BoilerPlateCodes || []);
    }
  }, [props.task]);

  useEffect(() => {
    if (task && task.BoilerPlateCodes) {
      const code = task.BoilerPlateCodes.find(
        (c) => c.languageId === Number(selectedLanguage.id)
      );

      if (code) {
        setIsUpdate(true);
        setState({ ...state, code: code.code, boilerPlateCodeId: code.id });
      } else {
        setIsUpdate(false);
        setState({ ...state, code: '', boilerPlateCodeId: null });
      }
    }
  }, [selectedLanguage, task]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    const language = availableLanguages.find((l) => l.id === Number(value));

    setSelectedLanguage(language);
  };

  const createCode = async () => {
    setLoading(true);

    const data = {
      AssignmentTaskId: task.id,
      languageId: selectedLanguage.id,
      code: state.code,
    };

    if (!data.code) {
      toast.error('Add some code');
      return;
    }

    if (!data.languageId) {
      toast.error('Select a valid coding language');
      return;
    }

    try {
      let res = '';
      if (!isUpdate) {
        res = await agent.Assignments.addBoilerPlateCode(data);
        props.setTask({
          ...task,
          BoilerPlateCodes: [...task.BoilerPlateCodes, res.data.data],
        });
        toast.success('Boilerplate Code added!!!');
      } else {
        res = await agent.Assignments.updateBoilerPlateCode(
          state.boilerPlateCodeId,
          data
        );
        const updatedCodes = task.BoilerPlateCodes.map((c) => {
          if (c.id === state.boilerPlateCodeId) {
            return { ...data, id: state.boilerPlateCodeId };
          }
          return c;
        });
        props.setTask({ ...task, BoilerPlateCodes: updatedCodes });

        toast.success('Boilerplate Code Updated!!!');
      }

      setLoading(false);
    } catch (err) {
      toast.error(ERROR_MSG);
      console.log(err, err.response);
      setLoading(false);
    }
  };

  const SimpleTaskDialog = () => {
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullScreen>
        <DialogTitle>
          <div className="flex items-center justify-between">
            <h3>Create Boilerplate Code : {task.name}</h3>
            <button type="button" className="red-btn f6 " onClick={handleClose}>
              Close
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="compiler">
            <div className="flex items-center justify-between mt2 mb2">
              <h4>Enter Your Code Here</h4>
              <h4> Language: {selectedLanguage.name}</h4>
              <select value={selectedLanguage.id} onChange={onLanguageChange}>
                {availableLanguages.map((l) => (
                  <option key={l.id} value={l.id}>
                    {' '}
                    {l.name}{' '}
                  </option>
                ))}
              </select>
            </div>
            <ol className="mb2">
              <li className="danger  f6">
                {`Enclose the part of the code in ${boilerPlateCodeDelimeter} that you want to show to user.`}{' '}
              </li>
              <li className="danger  f6">
                {`If ${boilerPlateCodeDelimeter} is not present in the code then user will see whole code including the boiler plate code.`}{' '}
              </li>
            </ol>
            <CodeEditor
              codeString={state.code}
              onCodeChange={(value) => setState({ ...state, code: value })}
              language={selectedLanguage || {}}
              isTemplateEditor
            />

            {!task.isLockedForEdit && isUpdate && (
              <button
                type="button"
                onClick={createCode}
                className="primary-btn pa3 mt3 ml-auto"
              >
                Update Boilerplate Code
              </button>
            )}
            {!task.isLockedForEdit && !isUpdate && (
              <button
                type="button"
                onClick={createCode}
                className="primary-btn pa3 mt3 ml-auto"
              >
                Create Boilerplate Code
              </button>
            )}
          </div>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  return (
    <>
      <button
        type="button"
        className="ml2 f6 secondary-btn pa2 mt2 mb2"
        onClick={handleClickOpen}
      >
        Boiler Plate Code
      </button>
      {SimpleTaskDialog()}
    </>
  );
};

export default CreateBoilerplateCode;
