import TablePagination from '@material-ui/core/TablePagination';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import agent from '../../agent';
import { sendAmplitudeData } from '../../common/amplitude';
import CustomSelect from '../../common/components/CustomSelect';
import Loader from '../../common/components/Loader';
import Tabs from '../../common/components/Tabs';
import { isValidGoogleMeetLink } from '../../common/functions';
import GeneralDialog from '../../common/GeneralDialog';
import {
  APPOINTMENT_OUTCOME,
  APPOINTMENT_STARTED_BY_OWNER,
} from '../../constants/amplitudeConstant';
import {
  getErrorMessage,
  getQueryStringByFilterOptions,
} from '../../constants/helperFunctions';
import {
  AppointmentFeedbackStatus,
  appointmentTypes,
  InterviewStatus,
  OPERATOR_DELIMITER,
  weekDays,
} from '../../constants/otherConstants';
import AppointmentList from './AppointmentList';
import './Appointments.scss';
import AppointmentStatusConfirmationDialog from './AppointmentStatusConfirmationDialog';
import SearchFilter from './SearchFilter';

let timeout = null;

const Appointment = () => {
  const [appointments, setAppointments] = useState([]);
  const [openAddOrUpdateLinkDialog, setOpenAddOrUpdateLinkDialog] =
    useState(false);
  const [meetingLink, setMeetingLink] = useState('');
  const [confirmedStatus, setConfirmedStatus] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAppointments, setShowAppointments] = useState({
    upcoming: true,
    old: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(25);
  const [searchByOption, setSearchByOption] = useState('name');
  const [searchString, setSearchString] = useState('');
  const user = useSelector((state) => state.common.user);
  const [feedback, setFeedback] = useState({});
  const [strikeSubject, setStrikeSubject] = useState('');
  const [appointmentType, setAppointmentType] = useState('All');
  const [reAllotType, setReAllotType] = useState('');
  const [reAllotOptionalDescription, setReAllotOptionalDescription] =
    useState('');

  function createFilterOptions(
    upcoming,
    searchByOption,
    searchString,
    newPage,
    rowsPerPage,
    appointmentType
  ) {
    const upcomingKey = 'upcoming';
    const filterOptions = {
      [searchByOption]: `%${searchString}%${OPERATOR_DELIMITER}like`,
      page: newPage,
      size: rowsPerPage,
      type: appointmentType,
    };

    if (upcoming) {
      filterOptions[upcomingKey] = upcoming;
    }

    return filterOptions;
  }

  const getFilteredUsers = async (filterOptions, showLoading = true) => {
    if (showLoading) setLoading(true);

    let optionString = getQueryStringByFilterOptions(filterOptions);

    try {
      const res = await agent.Appointments.getStudentsAppointment(optionString);
      const { totalItems, allAppointments } = res?.data?.data;
      setTotalCount(totalItems);
      setAppointments(allAppointments);
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  };

  const handleShowAppointments = async (appointmentType) => {
    try {
      const page = 0;
      const rowsPerPage = 25;
      const upcoming = appointmentType !== 'old';
      setShowAppointments({
        upcoming: upcoming,
        old: !upcoming,
      });
      const newFilterOptions = createFilterOptions(
        upcoming,
        searchByOption,
        searchString,
        page + 1,
        rowsPerPage
      );
      await getFilteredUsers(newFilterOptions, false);
      setPage(page);
      setRowsPerPage(rowsPerPage);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const handleChangePage = async (event, newPage) => {
    const newFilterOptions = createFilterOptions(
      showAppointments.upcoming,
      searchByOption,
      searchString,
      newPage + 1,
      rowsPerPage
    );
    await getFilteredUsers(newFilterOptions, false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    const newFilterOptions = createFilterOptions(
      showAppointments.upcoming,
      searchByOption,
      searchString,
      page + 1,
      event.target.value
    );
    await getFilteredUsers(newFilterOptions, false);
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const page = 0;
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        const newFilterOptions = createFilterOptions(
          showAppointments.upcoming,
          searchByOption,
          searchString,
          page + 1,
          rowsPerPage,
          appointmentType
        );
        await getFilteredUsers(newFilterOptions, false);
      }, 1000);

      setPage(page);
    };

    fetchData();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [searchByOption, searchString]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const newFilterOptions = createFilterOptions(
          showAppointments.upcoming,
          searchByOption,
          searchString,
          page + 1,
          rowsPerPage,
          appointmentType
        );
        await getFilteredUsers(newFilterOptions);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();
  }, [appointmentType]);

  const handleCloseDialogs = () => {
    setOpenAddOrUpdateLinkDialog(false);
  };

  const handleChangeStatus = async (status, selectedAppointment) => {
    try {
      if (!status) {
        return toast.error('Status not given');
      }

      setConfirmedStatus(status);
      setSelectedAppointment(selectedAppointment);
      setShowConfirmationDialog(true);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const handleConfirmStatusChange = async (selectedAppointment) => {
    try {
      if (!selectedAppointment) {
        return toast.error('Please select an appointment to change status');
      }

      if (
        confirmedStatus === AppointmentFeedbackStatus.reallot &&
        !reAllotType
      ) {
        return toast.error('Please select an reason to change status');
      }

      setShowConfirmationDialog(false);
      setLoading(true);

      const overallRating = calculateOverallRating(feedback);

      const finalFeedback = {
        ...feedback,
      };

      if (overallRating) {
        finalFeedback.OverallRating = overallRating;
      }

      await agent.Appointments.changeStatus({
        AppointmentId: selectedAppointment.id,
        status: confirmedStatus,
        appointmentStatus: selectedAppointment.appointmentStatus,
        feedback: Object.keys(finalFeedback).length > 0 ? finalFeedback : null,
        ...(confirmedStatus === AppointmentFeedbackStatus.warning
          ? {
              strike: {
                strikeSubject,
                strikeReason: feedback?.OverallFeedback,
              },
            }
          : {}),
        ...(confirmedStatus === AppointmentFeedbackStatus.reallot
          ? {
              reAllotReason: {
                type: reAllotType,
                description: reAllotOptionalDescription,
              },
            }
          : {}),
      });

      sendAmplitudeData(APPOINTMENT_OUTCOME, {
        appointmentType:
          selectedAppointment?.AppointmentSetup?.title ||
          selectedAppointment?.Assignment?.AppointmentSetup?.title,
        appointmentOwner: user?.name,
        slotDay: weekDays[selectedAppointment?.day],
        slotTime: selectedAppointment?.time,
        outcome: confirmedStatus,
      });

      setAppointments(
        appointments.filter(
          (appointment) => appointment.id !== selectedAppointment.id
        )
      );

      handleCloseDialogs();
      setFeedback({});
      setReAllotType('');
      setReAllotOptionalDescription('');
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMeetingLinks = async () => {
    if (!meetingLink) {
      return toast.error('Please add meeting link');
    }
    if (!isValidGoogleMeetLink(meetingLink)) {
      return toast.error('Please add a valid meeting link');
    }

    try {
      setLoading(true);
      await agent.Appointments.updateMeetingLinks({ meetingLink });
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.appointmentStatus != InterviewStatus.Completed) {
          return {
            ...appointment,
            meetingLink,
          };
        }
        return appointment;
      });

      sendAmplitudeData(APPOINTMENT_STARTED_BY_OWNER, {
        appointmentType: selectedAppointment?.AppointmentSetup?.title,
        appointmentOwner: user?.name,
        slotDay: weekDays[selectedAppointment?.day],
        slotTime: selectedAppointment?.time,
      });

      setAppointments(updatedAppointments);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
      handleCloseDialogs();
    }
  };

  function calculateOverallRating(feedback) {
    // Extract ratings from the feedback object
    const ratings = Object.values(feedback).filter((rating) => Number(rating));

    // Calculate the sum of ratings
    const sumOfRatings = ratings.reduce((total, rating) => total + rating, 0);

    // Calculate the overall rating
    const overallRating = sumOfRatings / ratings.length;

    // Scale the overall rating to be out of 10
    const overallRatingOutOf10 = overallRating * 2;

    return overallRatingOutOf10;
  }

  return (
    <div className="p1">
      <div className="flex justify-between items-center">
        <h1 className=" b ml-2"> Appointments </h1>
        {loading && <Loader />}
      </div>
      <div className="w-100">
        <Tabs
          components={[
            {
              title: 'Upcoming',
              component: (
                <AppointmentList
                  key={0}
                  appointments={appointments}
                  handleChangeStatus={handleChangeStatus}
                  handleShowAppointments={handleShowAppointments}
                  upcoming={true}
                  setAppointments={setAppointments}
                />
              ),
            },
            {
              title: 'Old',
              component: (
                <AppointmentList
                  key={1}
                  appointments={appointments}
                  handleChangeStatus={handleChangeStatus}
                  handleShowAppointments={handleShowAppointments}
                  upcoming={false}
                  setAppointments={setAppointments}
                />
              ),
            },
          ]}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginLeft: 1,
            }}
          >
            <Stack direction="row" sx={{ width: '100%', gap: '10px' }}>
              <SearchFilter
                searchByOption={searchByOption}
                setSearchByOption={setSearchByOption}
                searchString={searchString}
                setSearchString={setSearchString}
              />

              <CustomSelect
                value={appointmentType}
                onChange={(e) => {
                  setAppointmentType(e.target.value);
                }}
                options={Object.values(appointmentTypes)}
              />
            </Stack>

            <Stack>
              <TablePagination
                size="small"
                component="div"
                page={page}
                count={totalCount}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Stack>
        </Tabs>
      </div>

      <GeneralDialog
        open={openAddOrUpdateLinkDialog}
        onClose={handleCloseDialogs}
        title="Add Or Update Link"
      >
        <input
          type="text"
          value={meetingLink}
          onChange={(e) => setMeetingLink(e.target.value)}
          placeholder="Enter meeting link..."
          className="link-input"
          style={{
            padding: '0.5rem',
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginRight: '10px',
          }}
        />

        <button
          className="confirm-btn"
          onClick={handleUpdateMeetingLinks}
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '0.5rem 1rem',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Confirm
        </button>
      </GeneralDialog>

      <AppointmentStatusConfirmationDialog
        open={showConfirmationDialog}
        title="Confirm Status Change"
        message={`Are you sure you want to change the status to ${confirmedStatus}?`}
        onConfirm={() => handleConfirmStatusChange(selectedAppointment)}
        onCancel={() => setShowConfirmationDialog(false)}
        confirmButtonTitle="Confirm"
        cancelButtonTitle="Cancel"
        appointmentType={
          selectedAppointment?.AppointmentSetup?.type ||
          selectedAppointment?.Assignment?.AppointmentSetup?.type
        }
        confirmedStatus={confirmedStatus}
        feedback={feedback}
        setFeedback={setFeedback}
        strikeSubject={strikeSubject}
        setStrikeSubject={setStrikeSubject}
        reAllotType={reAllotType}
        setReAllotType={setReAllotType}
        reAllotOptionalDescription={reAllotOptionalDescription}
        setReAllotOptionalDescription={setReAllotOptionalDescription}
      />
    </div>
  );
};

export default Appointment;
