import * as React from 'react';
import { useState, useEffect } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import { Calendar } from '../../components/ui/calendar';
import { Button } from '../../components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { cn } from '../../lib/utils';

export function DatePickerWithRange({
  className,
  onDateRangeChange,
  initialDays,
}) {
  const [date, setDate] = useState(initialDays);

  useEffect(() => {
    if (initialDays) {
      setDate(initialDays);
    }
  }, [initialDays]);

  useEffect(() => {
    if (onDateRangeChange && date?.from && date?.to) {
      onDateRangeChange(date);
    }
  }, [date, onDateRangeChange]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date?.from && 'text-muted-foreground'
            )}
          >
            <FaCalendarAlt className="mr-2 h-4 w-4" />
            {date?.from ? (
              date?.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from || new Date()}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
