import React, { useState, useEffect } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Link } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';
import { questionStatus } from '../../constants/otherConstants';
import './Courses.scss';

function Course(props) {
  const [course, setCourse] = useState({});

  useEffect(() => {
    if (props.course) {
      setCourse(props.course);
    }
  }, [props.course]);

  return (
    <div className="course border shadow-md hover:shadow-lg font-poppins">
      <div className="flex items-center justify-between">
        <h3 className="border-b w-full">{course.name}</h3>
        {course.creator && !props.mentor && (
          <Link
            className="underline blue"
            to={`${pathNames.MENTOR_DETAILS}?MentorId=${course.creator.id}`}
          >
            {course.creator.name}
          </Link>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: course.description,
        }}
      ></div>
      <div className="flex items-center justify-start gap-6 py-2">
        <Link to={`${pathNames.COURSE_STUDENTS}?CourseId=${course.id}`}>
          <GroupIcon className="text-green-500 hover:text-green-600" />
        </Link>
        <Link to={`${pathNames.MESSAGES}?CourseId=${course.id}`}>
          <EmailIcon className="text-blue-500 hover:text-blue-600" />
        </Link>
        <Link to={`${pathNames.COURSE_PLAN}?CourseId=${course.id}`}>
          <AccountTreeIcon className="text-yellow-500 hover:text-yellow-600" />
        </Link>
        <Link to={`${pathNames.COURSE_DETAILS}?CourseId=${course.id}`}>
          <EditIcon className="text-purple-500 hover:text-purple-600" />
        </Link>
      </div>
    </div>
  );
}

export default Course;
