import React from 'react';

const MentorRatingsSkeleton = () => {
  return (
    <>
      <div className="flex gap-1 mb-10 text-start justify-start items-center">
        <div className="w-24 h-8 bg-gray-300 rounded-md animate-pulse"></div>
        <div className="w-24 h-8 bg-gray-300 rounded-md animate-pulse"></div>
      </div>

      {/* Class Metrics */}
      <div className="mb-6">
        <div className="w-48 h-6 bg-gray-300 mb-4 rounded-md animate-pulse"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 h-[10rem] animate-pulse"
            >
              <div className="text-2xl font-medium text-gray-600 flex items-center gap-2">
                {/* Placeholder for icons */}
                <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
              </div>
              <div className="w-32 h-10 mt-4 bg-gray-300 rounded-md animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Mock Interview Metrics */}
      <div className="mt-10">
        <div className="w-48 h-6 bg-gray-300 mb-4 rounded-md animate-pulse"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 h-[10rem] animate-pulse"
            >
              <div className="text-2xl font-medium text-gray-600 flex items-center gap-2">
                {/* Placeholder for icons */}
                <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="w-48 h-6 bg-gray-300 rounded-md animate-pulse"></div>
              </div>
              <div className="w-32 h-10 mt-4 bg-gray-300 rounded-md animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MentorRatingsSkeleton;
