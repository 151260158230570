import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import React from 'react';

function MockFeedbackDialog({
  open,
  onClose,
  feedback,
  isReAllot = false,
  reAllotReason = {},
}) {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <p>Mock Feedbacks</p>
          <Close className="cursor-pointer" onClick={onClose} />
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="flex flex-col gap-1">
          {Object.keys(feedback ?? {}).map((key) => (
            <p>
              <span className="b">{key}</span>: {feedback[key] || 'N/A'}
            </p>
          ))}
        </div>
        {isReAllot && reAllotReason && (
          <div className="py-2">
            <p className="font-semibold text-gray-700">
              <span className="font-bold text-black">Reason: </span>
              {reAllotReason?.type}
            </p>
            {reAllotReason?.description && (
              <p className="mt-2 text-gray-600">
                <span className="font-bold text-black">Description: </span>
                {reAllotReason?.description}
              </p>
            )}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MockFeedbackDialog;
