import React, { useState } from 'react';
import AddOrUpdateSQLTestCase from './AddOrUpdateSQLTestCase';
import agent from '../../../agent';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../constants/helperFunctions';
import { CircularProgress } from '@material-ui/core';

const SQLTestCases = ({ testCases, setTestCases, task, getATask }) => {
  const [loading, setLoading] = useState(false);

  const deleteTestCase = async (testCaseId) => {
    try {
      setLoading(true);
      await agent.Assignments.deleteTestCase(testCaseId);

      setTestCases((prev) => prev.filter((t) => t.id !== testCaseId));
      toast.success('Test case deleted successfully');
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col gap-3">
      {testCases?.length > 0 ? (
        testCases.map((testCase, index) => (
          <div
            className="flex w-full flex-col gap-2 py-2 p-2 shadow bg-white rounded-lg"
            key={testCase?.id}
          >
            <p className="font-medium mt-1 text-slate-700">
              Test Case {index + 1}
            </p>
            <div className="flex w-full justify-between">
              <div className="w-[45%]">
                <p className="my-1">Input</p>
                <div className="w-full border border-solid rounded border-slate-500">
                  <textarea
                    value={testCase?.input}
                    readOnly
                    rows={8}
                    className="w-full resize-none outline-none p-1"
                  ></textarea>
                </div>
              </div>
              <div className="w-[45%]">
                <p className="my-1">Output</p>
                <div className="w-full border border-solid rounded border-slate-500  ">
                  <textarea
                    rows={8}
                    readOnly
                    value={testCase?.output}
                    className="w-full resize-none outline-none p-1"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="flex gap-2 justify-end">
              <AddOrUpdateSQLTestCase
                isUpdate
                testCase={testCase}
                task={task}
                getATask={getATask}
              />
              <button
                onClick={() => deleteTestCase(testCase?.id)}
                className="bg-red-500 rounded-md text-white px-1 py-2 w-16"
              >
                {loading ? <CircularProgress size={16} /> : 'Delete'}
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex justify-center items-center h-[60vh]">
          <p className="font-medium text-2xl mt-1 text-slate-700 text-center">
            Test Cases Not Added
          </p>
        </div>
      )}
    </div>
  );
};

export default SQLTestCases;
