import React, { useState } from 'react';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Button } from '@material-ui/core';
import AppointmentFreeTimings from './AppointmentFreeTiming';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_APPOINTMENTS_SLOTS } from '../../constants/actionTypes';
import { ERROR_MSG } from '../../constants/otherConstants';
import { toast } from 'react-toastify';
import Loader from '../../common/components/Loader';
import agent from '../../agent';

function Slot({ appointment }) {
  const [showSlots, setShowSlots] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.common.user);
  const dispatch = useDispatch();

  const fetchMentorAppointments = async () => {
    setIsLoading(true);
    try {
      const res = await agent.Appointments.getAllAppointments(
        `?InterviewerId=${user.id}`
      );
      dispatch({
        type: GET_ALL_APPOINTMENTS_SLOTS,
        payload: res.data.data,
      });
      setShowSlots(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || ERROR_MSG);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetShowSlots = async () => {
    fetchMentorAppointments();
  };

  return (
    <div className="course border shadow-md hover:shadow-lg">
      <div className="flex items-center justify-between">
        <h3 className="border-b w-full">
          {appointment?.AppointmentSetup?.title}
        </h3>
      </div>
      {isLoading && <Loader />}
      <div
        className="mt-2"
        dangerouslySetInnerHTML={{
          __html: appointment?.AppointmentSetup?.description,
        }}
      ></div>
      <div className="actions">
        <Button onClick={handleSetShowSlots}>
          <AccountTreeIcon />
        </Button>

        {showSlots && (
          <AppointmentFreeTimings
            slotsLength={appointment?.AppointmentSetup?.slotsLength}
            AppointmentSetupId={appointment?.AppointmentSetupId}
            slots={appointment?.slots}
            setShowSlots={setShowSlots}
            appointment={appointment}
          />
        )}
      </div>
    </div>
  );
}

export default Slot;
