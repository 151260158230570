import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  AppointmentFeedbackStatus,
  MIN_OVERALL_FEEDBACK_LENGTH,
  appointmentTypeFeedbackFields,
  reAllotReasons,
  subjectiveStrikeComments,
} from '../../constants/otherConstants';
import { Rating } from '@mui/material';

const AppointmentStatusConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonTitle,
  cancelButtonTitle,
  appointmentType,
  feedback,
  setFeedback,
  confirmedStatus,
  strikeSubject,
  setStrikeSubject,
  reAllotType,
  setReAllotType,
  reAllotOptionalDescription,
  setReAllotOptionalDescription,
}) => {
  if (!appointmentType) {
    return null;
  }

  const allFieldsFilled = Object.keys(
    appointmentTypeFeedbackFields[appointmentType]
  )?.every((key) => feedback[key] !== undefined);

  const overallFeedbackValid =
    feedback?.OverallFeedback &&
    feedback?.OverallFeedback?.trim()?.split(/\s+/)?.length >=
      MIN_OVERALL_FEEDBACK_LENGTH;

  const overallFeedbackLength = feedback.OverallFeedback
    ? feedback.OverallFeedback.trim().split(/\s+/).length
    : 0;

  const isFeedbackRequired =
    confirmedStatus === AppointmentFeedbackStatus.failed ||
    confirmedStatus === AppointmentFeedbackStatus.passed ||
    confirmedStatus === AppointmentFeedbackStatus.warning;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <h2 className="text-xl b">{title}</h2>
        <p className="mt-1">{message}</p>
        {(isFeedbackRequired ||
          confirmedStatus === AppointmentFeedbackStatus.reallot) && (
          <div className="mt3">
            {confirmedStatus !== AppointmentFeedbackStatus.reallot &&
              Object.keys(appointmentTypeFeedbackFields[appointmentType])?.map(
                (key) => (
                  <div
                    key={key}
                    className="flex justify-between items-center mt3"
                  >
                    <h3>
                      {appointmentTypeFeedbackFields[appointmentType][key]}
                    </h3>

                    <Rating
                      onChange={(e) =>
                        setFeedback((prev) => ({
                          ...prev,
                          [key]: Number(e.target.value),
                        }))
                      }
                      name="size-small"
                      value={feedback[key] ?? 0}
                    />
                  </div>
                )
              )}
            {confirmedStatus === AppointmentFeedbackStatus.reallot && (
              <>
                <select
                  value={reAllotType}
                  onChange={(e) => setReAllotType(e.target.value)}
                  className="pa2 br2 w-100 mt3 border "
                >
                  <option value={''}>Select Re-allot Reason</option>
                  {Object.keys(reAllotReasons).map((k) => (
                    <option value={reAllotReasons[k]} key={k}>
                      {reAllotReasons[k]}
                    </option>
                  ))}
                </select>
                <textarea
                  onChange={(e) =>
                    setReAllotOptionalDescription(e.target.value)
                  }
                  value={reAllotOptionalDescription}
                  placeholder="Enter optional subjective description"
                  className="w-100 mt-2 border px-3 py-2"
                  rows={3}
                ></textarea>
              </>
            )}

            {confirmedStatus === AppointmentFeedbackStatus.warning && (
              <select
                value={strikeSubject}
                onChange={(e) => setStrikeSubject(e.target.value)}
                className="pa2 br2 w-100 mt3 globalSelect "
              >
                <option value={''}>Select Subjective Comment</option>
                {Object.keys(subjectiveStrikeComments).map((k) => (
                  <option value={k} key={k}>
                    {subjectiveStrikeComments[k]}
                  </option>
                ))}
              </select>
            )}

            {confirmedStatus !== AppointmentFeedbackStatus.reallot && (
              <textarea
                onChange={(e) => {
                  setFeedback({ ...feedback, OverallFeedback: e.target.value });
                }}
                value={feedback?.OverallFeedback ?? ''}
                className="w-100 mt-2 border"
                rows={5}
              ></textarea>
            )}

            {confirmedStatus !== AppointmentFeedbackStatus.reallot && (
              <div className="flex justify-between items-center">
                <p>Min Length: {MIN_OVERALL_FEEDBACK_LENGTH}</p>
                <p>{overallFeedbackLength}</p>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} style={{ color: '#e60000' }}>
          {cancelButtonTitle}
        </Button>

        {((allFieldsFilled && overallFeedbackValid) || !isFeedbackRequired) && (
          <Button
            variant="contained"
            style={{
              backgroundColor: '#1a73e8',
              color: 'white',
              fontWeight: '500',
            }}
            className="new-btn-1   "
            onClick={onConfirm}
          >
            {confirmButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

AppointmentStatusConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonTitle: PropTypes.string.isRequired,
  cancelButtonTitle: PropTypes.string.isRequired,
};

export default AppointmentStatusConfirmationDialog;
