import {
  classScheduleStatus,
  CONCEPT_CLASS,
  courseClassStatus,
  ERROR_MSG,
} from '../../constants/otherConstants';
import agent from '../../agent';
import React, { useEffect, useState } from 'react';
import {
  FaChalkboardTeacher,
  FaCalendarAlt,
  FaUser,
  FaCheckCircle,
  FaFileAlt,
  FaEdit,
  FaTrash,
  FaArchive,
  FaRegClock,
} from 'react-icons/fa';

import { groupBy } from '../../common/functions';
import moment from 'moment';
import { daysOfWeek } from '../../constants/otherConstants';
import { filterClassesByDay } from '../../constants/helperFunctions';
import { GET_COURSES } from '../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  formatTime,
  calculateStartTime,
} from '../../constants/helperFunctions';

const days = [
  {
    day: 'Monday',
  },
  {
    day: 'Tuesday',
  },
  {
    day: 'Wednesday',
  },
  {
    day: 'Thursday',
  },
  {
    day: 'Friday',
  },
  {
    day: 'Saturday',
  },
  {
    day: 'Sunday',
  },
];

const CourseTime = ({
  course,
  setClassEditData,
  setCreateClassDialogOpen,
  setEditingClass,
  isLive,
}) => {
  const courses = useSelector((state) => state.CoursesReducers.courses);
  const user = useSelector((state) => state.common.user);

  const dispatch = useDispatch();
  const [activeDay, setActiveDay] = useState(daysOfWeek[0]);
  const [courseClasses, setCourseClasses] = useState([]);
  const [filterClasses, setFilterClasses] = useState([]);

  useEffect(() => {
    (async () => {
      if (!course.id) {
        return;
      }
      try {
        const classesRes = await agent.Courses.getAllCourseClass(
          `?CourseId=${course.id}&status=${
            isLive ? courseClassStatus.LIVE : courseClassStatus.ARCHIVE
          }`
        );

        const classes = classesRes?.data?.data;

        const groupClasses = classes ? groupBy(classes, 'day') : {};
        const courseClasses = days.map((d) => ({
          ...d,
          classes: groupClasses[d.day]
            ? groupClasses[d.day].sort((a, b) =>
                moment(a.startTime, 'HH:mm:ss').subtract(
                  moment(b.startTime, 'HH:mm:ss')
                )
              )
            : [],
        }));
        const filterClasses = filterClassesByDay(courseClasses, activeDay);
        setCourseClasses(courseClasses);
        setFilterClasses(filterClasses);
      } catch (err) {
        console.log(err, err.response);
      }
    })();
  }, [course, isLive]);

  const handleTabClick = (day) => {
    setActiveDay(day);
    const filterClasses = filterClassesByDay(courseClasses, day);
    setFilterClasses(filterClasses);
  };

  const archiveClass = async (time) => {
    try {
      await agent.Courses.archiveCourseClass(time.id, {
        status:
          time.status == courseClassStatus.LIVE
            ? courseClassStatus.ARCHIVE
            : courseClassStatus.LIVE,
      });

      const newCourses = courses?.map((c) => {
        if (c.id === course.id) {
          const newClasses = c.classes.filter((cl) => cl.id !== time.id);
          return { ...course, classes: newClasses };
        }
        return c;
      });
      dispatch({ type: GET_COURSES, payload: newCourses });

      toast.success(
        `Class ${
          time.status == courseClassStatus.LIVE
            ? courseClassStatus.ARCHIVE
            : courseClassStatus.LIVE
        }`
      );
    } catch (err) {
      console.log(err, err.response);
      toast.error(err.response.data.message || ERROR_MSG);
    }
  };

  // Function to check if the "Join Now" button should be enabled
  const isJoinButtonEnabled = (startTime) => {
    const now = moment();
    const classStartTime = moment(startTime, 'HH:mm:ss');
    const tenMinutesBefore = classStartTime.clone().subtract(10, 'minutes');
    const twentyMinutesAfter = classStartTime.clone().add(20, 'minutes');

    return now.isBetween(tenMinutesBefore, twentyMinutesAfter);
  };

  // when mentor joining in any class
  const joinClass = async (courseClassTimeId, scheduledClassStatus) => {
    if (scheduledClassStatus === classScheduleStatus.CANCELLED) {
      toast.error('Class Cancelled');
      return;
    }

    try {
      await agent.Courses.updateMentorClassJoinStatus({ courseClassTimeId });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="flex justify-start gap-4 px-5 pt-4 flex-wrap">
        {daysOfWeek.map((day) => (
          <button
            key={day}
            className={`py-2 px-12 rounded-md ${
              activeDay === day
                ? 'sharpener-gradient-background text-white'
                : 'bg-gray-200 text-black'
            } hover:bg-red-200 transition-colors`}
            onClick={() => handleTabClick(day)}
          >
            {day}
          </button>
        ))}
      </div>

      {/* Day Specific Content */}
      <div className="px-5 pb-10 pt-10 flex flex-wrap gap-7">
        {filterClasses?.map((item) => {
          return (
            <div className=" w-full sm:w-[25rem] min-h-[36rem] max-h-fit border shadow-md hover:shadow-xl rounded-md transition-transform p-4 flex flex-col gap-2 relative cursor-pointer">
              <div className="flex flex-col gap-x-4 gap-y-2">
                <div
                  key={item?.id}
                  className="flex items-center justify-start gap-3"
                >
                  <FaCalendarAlt className="text-blue-500" />
                  <h2 className="text-base font-medium">Day : </h2>
                  <p className="text-base font-light">{item?.day}</p>
                </div>
                <div className="flex items-center gap-3">
                  <FaChalkboardTeacher className="text-blue-500" />
                  <h2 className="text-base font-medium">Class Type : </h2>
                  <p className="text-base font-light">{item?.type}</p>
                </div>
                <div className="flex items-center gap-3">
                  <FaRegClock className="text-blue-500" />
                  <h2 className="text-base font-medium">Start Time : </h2>
                  <p className="text-base font-light">
                    {formatTime(
                      item?.scheduleStatus === classScheduleStatus.POSTPONED
                        ? item.postponedTime
                        : item?.startTime
                    )}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <FaUser className="text-blue-500" />
                  <h2 className="text-base font-medium">Mentor : </h2>
                  <p className="text-base font-light">
                    {item?.Mentor?.name || 'N/A'}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <FaCheckCircle className="text-green-500" />
                  <h2 className="text-base font-medium">Status : </h2>
                  <p
                    className={`text-base font-bold ${
                      item?.scheduleStatus === classScheduleStatus.ON_TIME &&
                      'text-green-500'
                    }
                    ${
                      item?.scheduleStatus === classScheduleStatus.CANCELLED &&
                      'text-red-500'
                    }
                    ${
                      item?.scheduleStatus === classScheduleStatus.POSTPONED &&
                      'text-orange-500'
                    }
                    `}
                  >
                    {item?.scheduleStatus}
                  </p>
                </div>
              </div>
              <hr className="mt-2" />
              <div className="flex items-center gap-3 my-2">
                <FaFileAlt className="text-blue-500" />
                <h2 className="text-base font-medium">Assignments : </h2>
              </div>

              <div className=" space-y-2 mb-5 overflow-y-auto h-[16rem]">
                {item?.assignments.map((assignment) => (
                  <div
                    key={assignment.id}
                    className="flex justify-between items-center border p-2 rounded-md"
                  >
                    <div className="text-sm flex gap-1 flex-col">
                      <div className="">
                        <p>Assignment name : {assignment.name} </p>
                      </div>
                      <div className=" flex gap-1">
                        <p>Start At : </p>
                        <p>
                          {calculateStartTime(
                            assignment?.scheduleStatus ===
                              classScheduleStatus.POSTPONED
                              ? assignment.postponedTime
                              : assignment?.startTime,
                            assignment.bufferTime || 0
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {user.id == item?.MentorId && item.type !== CONCEPT_CLASS && (
                <div className="absolute bottom-4 left-0 flex justify-between gap-10 text-sm w-full px-5">
                  <button
                    className={`bg-blue-500 text-white py-2 px-4 rounded-full ${
                      isJoinButtonEnabled(
                        item?.scheduleStatus === classScheduleStatus.POSTPONED
                          ? item.postponedTime
                          : item?.startTime
                      ) &&
                      item?.scheduleStatus !== classScheduleStatus.CANCELLED
                        ? ''
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                    disabled={
                      !isJoinButtonEnabled(
                        item?.scheduleStatus === classScheduleStatus.POSTPONED
                          ? item.postponedTime
                          : item?.startTime
                      ) &&
                      item?.scheduleStatus !== classScheduleStatus.CANCELLED
                    }
                    onClick={() => {
                      if (
                        isJoinButtonEnabled(
                          item?.scheduleStatus === classScheduleStatus.POSTPONED
                            ? item.postponedTime
                            : item?.startTime
                        ) &&
                        item?.scheduleStatus !== classScheduleStatus.CANCELLED
                      ) {
                        joinClass(item?.id, item?.scheduleStatus);
                        window.open(item?.meetingLink, '_blank');
                      }
                    }}
                  >
                    Join Now
                  </button>
                  <div className="flex justify-end items-center gap-5">
                    <button
                      className="flex items-center text-red-500 hover:text-red-700"
                      onClick={() => {
                        archiveClass(item);
                      }}
                    >
                      {item.status === courseClassStatus.LIVE ? (
                        <FaTrash className=" text-xl" />
                      ) : (
                        <FaArchive className=" text-xl" />
                      )}
                    </button>
                    <button
                      className="flex items-center text-blue-500 hover:text-blue-700"
                      onClick={() => {
                        setClassEditData(item);
                        setCreateClassDialogOpen(true);
                        setEditingClass(true);
                      }}
                    >
                      <FaEdit className=" text-2xl" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CourseTime;
