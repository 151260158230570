import { getObjectFromQueryString } from '../../../common/functions';
import agent from '../../../agent';
import React, { useEffect, useState } from 'react';
import AddOrUpdateSQLTestCase from './AddOrUpdateSQLTestCase';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../constants/helperFunctions';
import { useLocation } from 'react-router-dom';
import SQLTestCases from './SQLTestCases';
import AddBoilerPlate from './AddBoilerPlate';

const CreateSQLTestCases = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [testCases, setTestCases] = useState([]);
  const [task, setTask] = useState({});

  const getATask = async () => {
    const filters = getObjectFromQueryString(location.search);

    if (Number(filters['AssignmentId']) && Number(filters['TaskId'])) {
      try {
        setLoading(true);
        const res = await agent.Assignments.getATask(filters.TaskId);
        const task = res?.data?.data;
        setTask(task);
        const testCases = task?.TaskTestCases || [];
        testCases.sort((a, b) => a.id - b.id);
        setTestCases(testCases);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getATask();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <p className="text-3xl font-semibold text-slate-800">Loading...</p>
      </div>
    );
  }

  return (
    <div className="w-full overflow-hidden px-2">
      <div className="flex justify-between w-full items-center my-4">
        <h2 className="text-xl text-slate-800 text-semibold">
          SQL Test Cases :{' '}
          <span className="text-base text-blue-900">{task?.name}</span>
        </h2>

        <div className="flex gap-3">
          <AddBoilerPlate
            task={task}
            setTask={setTask}
            isUpdate={task?.BoilerPlateCodes?.length > 0}
          />
          <AddOrUpdateSQLTestCase task={task} getATask={getATask} />
        </div>
      </div>

      <SQLTestCases
        testCases={testCases}
        setTestCases={setTestCases}
        task={task}
        getATask={getATask}
      />
    </div>
  );
};

export default CreateSQLTestCases;
