import React, { useEffect, useState } from 'react';

import moment from 'moment';
import agent from '../../agent';
import Loader from '../../common/components/Loader';
import './AssignmentSubmission.scss';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { getParameterByName } from '../../common/functions';
import { pointsTypes } from '../../constants/data';
import { getErrorMessage } from '../../constants/helperFunctions';
import { ERROR_MSG } from '../../constants/otherConstants';
import { store } from '../../store';
import UserFlags from './UserFlags';
import UserStrikes from './UserStrikes';

const availablePointDestination = {
  BOTH: 'Add Both',
  ONLY_POINTS: 'Only Points',
  ONLY_WALLET: 'Only Wallet',
};

const UserDetails = ({ location: { search } }) => {
  const [user, setUser] = useState({ Batch: {} });
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userPoint, setUserPoint] = useState({
    pointType: pointsTypes.BONUS_POINTS,
    pointDestination: availablePointDestination.BOTH,
  });
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [courses, setCourses] = useState([]);
  const [undoCourseSubmissionDialogOpen, setUndoCourseSubmissionDialogOpen] =
    useState(false);
  const [
    undoAssignmentSubmissionDialogOpen,
    setUndoAssignmentSubmissionDialogOpen,
  ] = useState(false);
  const [selectedAssignmentForUndo, setSelectedAssignmentForUndo] =
    useState(null);

  const getFilterString = (filterOptions) => {
    let optionString = '';

    Object.keys(filterOptions).forEach((k) => {
      if (filterOptions[k] != null) {
        switch (k) {
          default: {
            optionString += `${k}=${filterOptions[k]}&`;
            break;
          }
        }
      }
    });

    optionString = optionString.substring(0, optionString.length - 1);
    if (optionString.length > 0) {
      optionString = `?${optionString}`;
    }

    return optionString;
  };
  useEffect(() => {
    (async () => {
      const UserId = getParameterByName('UserId', search);
      const CourseId = getParameterByName('CourseId', search);
      if (UserId) {
        const [userData, courseData] = await Promise.all([
          agent.User.getUserDetails(UserId),
          agent.Courses.getAllCourses(`?page=1&size=100`),
        ]);
        setUser(userData.data.data);
        setCourses(courseData.data.data);
      }
      setFilterOptions({ ...filterOptions, CourseId, UserId });
    })();
  }, []);

  useEffect(() => {
    getUserAssignments();
  }, [filterOptions]);

  const getUserAssignments = async () => {
    try {
      if (filterOptions.CourseId && filterOptions.UserId) {
        const queryString = getFilterString(filterOptions);
        if (queryString) {
          setLoading(true);

          const res = await agent.AssignmentSubmissions.getUserAssignments(
            queryString
          );
          setAssignments(res.data.data);
          setLoading(false);
        }
      } else {
        setAssignments([]);
      }
    } catch (err) {
      setLoading(false);
      toast.error(ERROR_MSG);
      console.log(err, err.response);
    }
  };

  const validateUserPoints = (points = userPoint) => {
    if (!points.points) {
      setError('*Points are required');
      return false;
    }
    if (!points.pointType) {
      setError('*Please select a point type');
      return false;
    }
    return true;
  };

  const updateUserPoints = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!validateUserPoints()) {
      return false;
    }
    setError(null);
    const data = { ...userPoint, userId: user.id };
    setLoading(true);
    agent.User.addPoints(data)
      .then(() => {
        // console.log(res);
        toast.success('Points added!!!');
        if (data.pointDestination !== availablePointDestination.ONLY_WALLET) {
          setUser({
            ...user,
            totalPoints: Number(user.totalPoints) + Number(data.points),
          });
        }

        setUserPoint({ ...userPoint, points: 0 });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        setLoading(false);
      });
  };
  const updateUserDetails = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    agent.User.updateUserDetails({ user })
      .then(() => {
        setLoading(false);
        toast.success('User Details Updated!!!');
        setIsUpdatingUser(false);
      })
      .catch((err) => {
        toast.error(ERROR_MSG);
        console.log(err, err.response);
        setLoading(false);
      });
  };

  const undoParticularAssignmentSubmission = async () => {
    if (!selectedAssignmentForUndo) return;

    const data = {
      UserId: user.id,
      AssignmentId: selectedAssignmentForUndo.id,
    };
    setLoading(true);
    try {
      await agent.AssignmentSubmissions.undoParticularAssignmentSubmission(
        data
      );
      toast.success('Assignment Undo Successfully!!!');
      getUserAssignments();
      setLoading(false);
      handleUndoAssignmentSubmissionDialog();
    } catch (err) {
      toast.error(err?.response.data?.message || ERROR_MSG);
      setLoading(false);
    }
  };

  const handleUndoCourseSubmissionDialog = () => {
    setUndoCourseSubmissionDialogOpen(!undoCourseSubmissionDialogOpen);
  };

  const UndoCourseSubmissionDialog = () => {
    return (
      <Dialog
        open={undoCourseSubmissionDialogOpen}
        onClose={handleUndoCourseSubmissionDialog}
      >
        <DialogTitle>Undo course submission</DialogTitle>
        <DialogContent>
          <div>
            <h4>Do you want to undo this course submission</h4>
            <p className="mt2">
              Note: This will delete all submissions of user for this course
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={undoCourseSubmission}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo Submission
          </button>
          <button
            type="button"
            onClick={handleUndoCourseSubmissionDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleUndoAssignmentSubmissionDialog = () => {
    setUndoAssignmentSubmissionDialogOpen(!undoAssignmentSubmissionDialogOpen);
  };

  const UndoAssignmentSubmissionDialog = () => {
    return (
      <Dialog
        open={undoAssignmentSubmissionDialogOpen}
        onClose={handleUndoAssignmentSubmissionDialog}
      >
        <DialogTitle>Undo assignment submission</DialogTitle>
        <DialogContent>
          <div>
            <h4>
              Do you want to undo submissions of{' '}
              {selectedAssignmentForUndo?.name}
            </h4>
            <p className="mt2">
              Note: This will delete all submissions of user for this assignment
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={undoParticularAssignmentSubmission}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo Submission
          </button>
          <button
            type="button"
            onClick={handleUndoAssignmentSubmissionDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const undoCourseSubmission = async (e) => {
    if (!filterOptions.CourseId) {
      return;
    }

    const data = {
      UserId: user.id,
      CourseId: filterOptions.CourseId,
    };

    setLoading(true);
    try {
      await agent.AssignmentSubmissions.undoCourseSubmission(data);
      toast.success('Course Undo Successfully!!!');

      const [, User] = await Promise.all([
        getUserAssignments(),
        agent.User.getUserDetails(user.id),
      ]);

      setUser(User.data.data);
      handleUndoCourseSubmissionDialog();
    } catch (err) {
      toast.error(ERROR_MSG);
      console.log(err, err.response);
    }
    setLoading(false);
  };

  const UserAssignment = ({ a }) => {
    const [showSections, setShowSections] = useState(false);
    const [sections, setSections] = useState([]);

    const undoAssignmentSection = async (sectionStatus) => {
      const { AssignmentId, SectionId, AttemptId } = sectionStatus || {};

      if (!AssignmentId || !SectionId) {
        return toast.error('Assignment or Section should not be null');
      }
      const data = {
        UserId: user.id,
        AssignmentId,
        SectionId,
      };

      try {
        let res = await agent.Assignments.undoAssignmentSection(data);
        res = res.data.data;
        toast.success('Section Undo Successfully!!!');
        getUserAssignmentSections(AssignmentId, AttemptId);

        if (res?.undoneUserAssignment) {
          getUserAssignments();
          toast.success('Assignment Undo Successfully!!!');
        }
      } catch (err) {
        toast.error(getErrorMessage(err));
      }
    };
    const UserAssignmentSections = () => {
      return (
        <>
          {sections?.map((section) => {
            const sectionStatus = section.sectionStatus;
            return (
              <tr key={section.id} className="bg-slate-100 hover:bg-blue-100">
                <td> {section.name} </td>
                <td>
                  {sectionStatus?.startDateTime
                    ? moment(sectionStatus?.startDateTime).format(
                        'MMMM Do YY, hh:mm a'
                      )
                    : 'Not started'}
                </td>
                <td>
                  {sectionStatus?.endDateTime
                    ? moment(sectionStatus?.endDateTime).format(
                        'MMMM Do YY, hh:mm a'
                      )
                    : 'Not started'}
                </td>
                <td className={sectionStatus?.submissionDate ? 'green' : 'red'}>
                  {sectionStatus?.submissionDate
                    ? moment(sectionStatus?.submissionDate).format(
                        'MMMM Do YYYY'
                      )
                    : 'Not submitted'}
                </td>
                <td
                  className={
                    sectionStatus?.isCompleted
                      ? sectionStatus?.isReviewed
                        ? 'green'
                        : 'yellow'
                      : 'red'
                  }
                >
                  {sectionStatus?.isCompleted
                    ? sectionStatus?.isReviewed
                      ? 'Review Done'
                      : 'Review Pending'
                    : 'Not Submitted'}
                </td>
                <td
                  className={
                    sectionStatus?.pointsAwarded != null ? 'green' : 'red'
                  }
                >
                  {sectionStatus?.pointsAwarded !== null
                    ? sectionStatus?.pointsAwarded
                    : 'Review is pending'}
                </td>
                <td className="flex gap-1">
                  <button
                    class="px-3 py-2 bg-red-500 text-white rounded w-full"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      undoAssignmentSection(sectionStatus);
                    }}
                  >
                    Undo section
                  </button>
                </td>
              </tr>
            );
          })}
        </>
      );
    };
    const getUserAssignmentSections = async (assignmentId, attemptId) => {
      if (!a.startDateTime) {
        return toast.error('Assignment not started');
      }
      try {
        const res = await agent.Assignments.getUserAssignmentSections(
          `userId=${user.id}&&AssignmentId=${assignmentId}&&AttemptId=${attemptId}`
        );

        setSections(res.data.data);
        setShowSections(true);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    };

    return (
      <>
        <tr
          key={a.Assignment.id}
          onClick={() =>
            store.dispatch(
              push(`/user/${user.id}/assignment/${a.Assignment.id}`, {
                user,
              })
            )
          }
        >
          <td> {a.Assignment.name} </td>
          <td>
            {a.startDateTime
              ? moment(a.startDateTime).format('MMMM Do YY, hh:mm a')
              : 'Not started'}
          </td>
          <td>
            {a.endDateTime
              ? moment(a.endDateTime).format('MMMM Do YY, hh:mm a')
              : 'Not started'}
          </td>
          <td className={a.submissionDate ? 'green' : 'red'}>
            {a.submissionDate
              ? moment(a.submissionDate).format('MMMM Do YYYY')
              : 'Not submitted'}
          </td>
          <td
            className={
              a.isCompleted ? (a.isReviewed ? 'green' : 'yellow') : 'red'
            }
          >
            {a.isCompleted
              ? a.isReviewed
                ? 'Review Done'
                : 'Review Pending'
              : 'Not Submitted'}
          </td>
          <td className={a.pointsAwarded != null ? 'green' : 'red'}>
            {a.pointsAwarded !== null ? a.pointsAwarded : 'Review is pending'}
          </td>
          <td className="flex gap-1">
            <button
              class="primary-btn"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAssignmentForUndo(a.Assignment);
                handleUndoAssignmentSubmissionDialog();
              }}
            >
              Undo
            </button>
            <button
              class="px-2 py-1 rounded bg-blue-500 text-white"
              onClick={(e) => {
                e.stopPropagation();
                getUserAssignmentSections(a.Assignment.id, a.AttemptId);
              }}
            >
              Sections
            </button>
          </td>
        </tr>

        {showSections && (
          <UserAssignmentSections
          // sections={sections}
          // setShowSections={setShowSections}
          />
        )}
      </>
    );
  };

  return (
    <div className="user-details">
      <nav className="nav">
        <h2> {user.name} </h2>
        <div className="flex items-center ">
          <UserFlags key={user.id} user={user} setUser={setUser} />
          <UserStrikes key={user.id} user={user} setUser={setUser} />

          {/* <Link
            className="secondary-btn pa2 br2 mr2"
            to={{
              pathname: `/user/isa/${user.id}`,
              user: user,
            }}
          >
            See student ISA
          </Link> */}
          {/* <button type='button' className='secondary-btn pa2 br2'>
            <Link to={`/batch/${user.BatchId}`} className='white' > Go to dashboard </Link>
          </button> */}
        </div>
      </nav>
      <div className="details">
        <div className="flex items-center justify-between">
          <h3>User details</h3>
          {/* <button
            className={isUpdatingUser ? "red-btn" : "primary-btn"}
            onClick={() => setIsUpdatingUser(!isUpdatingUser)}
          >
            {isUpdatingUser ? "Cancel" : "Edit User Details"}
          </button> */}
        </div>
        <form onSubmit={updateUserDetails} id="userdetails-form">
          <div className="content">
            <div className="flex items-center ">
              <p>{`Email: `} </p>
              {isUpdatingUser ? (
                <input
                  required={true}
                  type="email"
                  className="ml2"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              ) : (
                <p className="ml2">{user.email} </p>
              )}
            </div>
            <div className="flex items-center ">
              <p>{`Phone no.: `} </p>
              {isUpdatingUser ? (
                <input
                  required={true}
                  type="number"
                  value={user.mobileNo}
                  onChange={(e) =>
                    setUser({ ...user, mobileNo: e.target.value })
                  }
                  className="ml2"
                />
              ) : (
                <p className="ml2">{user.mobileNo} </p>
              )}
            </div>

            <div className="flex items-center ">
              <p>{`Total Points:`} </p>
              <p className="ml2">{user.totalPoints}</p>
            </div>
          </div>
          {isUpdatingUser && (
            <button
              type="submit"
              form="userdetails-form"
              className="secondary-btn center pa2 br2 mt4 w-25"
            >
              Update
            </button>
          )}
        </form>
      </div>

      <div className="details">
        <h3>Add User Points</h3>
        <div className="w-100">
          <form onSubmit={updateUserPoints}>
            <div className="flex items-center justify-between w-100">
              <input
                type="number"
                placeholder="User points"
                className="w-50 "
                value={userPoint.points}
                onChange={(e) =>
                  setUserPoint({ ...userPoint, points: e.target.value })
                }
                required
              />

              {/* <select onChange={(e) => setUserPoint({ ...userPoint, pointType: e.target.value }) }>
                            <option value=''>Select Point type</option>
                            {
                                Object.keys(pointsTypes).map(t => (
                                    <option value={pointsTypes[t]} key={t}>{pointsTypes[t]}</option>
                                ))
                            }
                        </select> */}
              <select
                value={userPoint.pointDestination}
                className="w-25"
                onChange={(e) =>
                  setUserPoint({
                    ...userPoint,
                    pointDestination: e.target.value,
                  })
                }
              >
                {Object.keys(availablePointDestination).map((t) => (
                  <option value={availablePointDestination[t]} key={t}>
                    {availablePointDestination[t]}
                  </option>
                ))}
              </select>
              <button type="submit" className="primary-btn  w-20">
                Add Points
              </button>
            </div>
            {error && <p className="error">{error} </p>}
          </form>
        </div>
      </div>
      <div className="assignments">
        <div className="flex items-center justify-between mt2 mb2">
          <h3>User Assignments</h3>
          <div className="flex items-center">
            <p className="danger b mr2">
              {!filterOptions.CourseId && '*Please select a course'}
            </p>
            {/* {filterOptions.CourseId && (
              <button
                className="primary-btn mr2"
                onClick={handleUndoCourseSubmissionDialog}
              >
                Undo Course Submissions
              </button>
            )} */}
            <select
              className="pa2 br2"
              value={filterOptions.CourseId}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  CourseId: Number(e.target.value),
                })
              }
            >
              <option value={''}> Select a course </option>

              {courses?.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <table className="assignment-table">
          <thead>
            <tr>
              <th>Assignment Name</th>
              <th>Started On</th>
              <th>Last Date</th>
              <th>Submission Date</th>
              <th>Status</th>
              <th>Points awarded</th>
              <th>Undo Assignment</th>
            </tr>
          </thead>
          <tbody>
            {assignments?.length > 0 ? (
              assignments?.map((a) => <UserAssignment key={a.id} a={a} />)
            ) : (
              <tr>
                <td>
                  No Assignment present in this course for {user.name}
                  <br />
                  Either the Course is not having any assignments or the User is
                  not present in the Course
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {UndoCourseSubmissionDialog()}
      {UndoAssignmentSubmissionDialog()}
      {loading && <Loader />}
    </div>
  );
};

export default UserDetails;
