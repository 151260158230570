import { Assignment, Close } from '@material-ui/icons';
import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MonacoEditor from '../../../common/components/MonacoCodeEditor';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../constants/helperFunctions';
import agent from '../../../agent';
import { codingLanguageIds } from '../../../constants/otherConstants';

const AddBoilerPlate = ({ task = {}, isUpdate, setTask }) => {
  const [open, setOpen] = useState(false);

  const [boilerplateCode, setBoilerplateCode] = useState({
    languageId: codingLanguageIds.sql,
    code: '',
    AssignmentTaskId: task.id,
  });
  const [loading, setLoading] = useState(false);

  const handleBoilerplateCodeChange = (value) => {
    setBoilerplateCode({ ...boilerplateCode, code: value });
  };

  useEffect(() => {
    if (isUpdate) {
      setBoilerplateCode(task?.BoilerPlateCodes[0]);
    }
  }, [task]);

  const addOrUpdateBoilerPlateCode = async () => {
    const data = boilerplateCode;

    if (!data.code) {
      toast.error('Add some code');
      return;
    }

    if (!data.languageId) {
      toast.error('Select a valid coding language');
      return;
    }

    try {
      setLoading(true);
      let res = '';
      if (!isUpdate) {
        res = await agent.Assignments.addBoilerPlateCode(data);
        setTask({
          ...task,
          BoilerPlateCodes: [...task.BoilerPlateCodes, res.data.data],
        });
        toast.success('Boilerplate Code added!!!');
      } else {
        if (!boilerplateCode.id) {
          toast.error('Boilerplate Code not found');
          return;
        }
        res = await agent.Assignments.updateBoilerPlateCode(
          boilerplateCode.id,
          data
        );

        const updatedCodes = task.BoilerPlateCodes.map((c) => {
          if (c.id === boilerplateCode.id) {
            return { ...data, id: boilerplateCode.id };
          }
          return c;
        });
        setTask({ ...task, BoilerPlateCodes: updatedCodes });

        toast.success('Boilerplate Code Updated!!!');
      }
    } catch (err) {
      toast.error(getErrorMessage(err));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="px-5 py-2 bg-purple-500 text-white rounded-md"
        onClick={() => setOpen(true)}
      >
        {isUpdate ? 'Update Boiler Plate' : 'Add Boiler Plate'}
      </button>

      {open && (
        <Dialog
          open={open}
          sx={{ '& .MuiDialog-paper': { minHeight: 'calc(100% - 64px)' } }}
          fullWidth
          maxWidth="lg"
        >
          <div className="w-full h-full p-2 flex flex-col gap-5">
            <div className="flex justify-between items-center px-1 py-2">
              <h2 className="text-xl font-bold">
                {isUpdate ? 'Update' : 'Add'} Boiler Plate
              </h2>

              <button
                className="w-10 h-10 rounded-full bg-gray-300"
                onClick={() => setOpen(false)}
              >
                <Close />
              </button>
            </div>

            <div className="w-full flex-grow h-[calc(100vh-200px)]">
              <MonacoEditor
                language="sql"
                height="100%"
                onChange={handleBoilerplateCodeChange}
                value={boilerplateCode.code}
              />
            </div>

            <div>
              <button
                className="px-5 py-2 bg-purple-500 text-white rounded-md"
                onClick={addOrUpdateBoilerPlateCode}
                disabled={loading}
              >
                {isUpdate ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default AddBoilerPlate;
