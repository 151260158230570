import { CircularProgress, Dialog } from '@material-ui/core';
import MonacoEditor from '../../../common/components/MonacoCodeEditor';

import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { getErrorMessage } from '../../../constants/helperFunctions';
import agent from '../../../agent';
import { getObjectFromQueryString } from '../../../common/functions';
import {
  extractCodeToShowUser,
  mergeCode,
  validateSQLInputFormat,
} from '../../../helpers';
import { Switch } from '../../ui/switch';

const AddOrUpdateSQLTestCase = ({
  isUpdate,
  task,
  testCase = {},
  getATask = () => {},
}) => {
  const location = useLocation();

  const queryParams = getObjectFromQueryString(location.search);
  const { TaskId } = queryParams;
  const { solution } = task || {};

  const [selectedTab, setSelectedTab] = useState('boilerplate');
  const [boilerplateCode, setBoilerplateCode] = useState(
    testCase.boilerPlateCode || ''
  );
  const [solutionCode, setSolutionCode] = useState(solution || '');
  const [input, setInput] = useState(testCase.input || '');
  const [output, setOutput] = useState(testCase.output || '');
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(testCase.isHidden || false);

  const [loading, setLoading] = useState({
    saveTestCase: false,
    generatingOutput: false,
  });

  const handleBoilerplateCodeChange = (value) => {
    setBoilerplateCode(value);
  };

  const handleOutputCodeChange = (value) => {
    setSolutionCode(value);
  };

  const generateTestCaseOutput = async () => {
    if (!boilerplateCode) {
      toast.error('Please add boilerplate code');
      return;
    }
    if (!solutionCode) {
      toast.error('Please add output code');
      return;
    }

    if (!input) {
      toast.error('Please add input');
      return;
    }

    const data = {
      testCases: [
        {
          input,
        },
      ],
      code: mergeCode(boilerplateCode, solutionCode),
      languageId: 82,
      isRapidApi: false,
    };

    try {
      setLoading({ ...loading, generatingOutput: true });
      const response = await agent.Assignments.runTestCases(data);
      const jsonResponse = response.data.data;
      let jsonGetSolution = {
        status: { description: 'Queue' },
        stderr: null,
        compile_output: null,
      };
      const tokens = jsonResponse.map((r) => r.token);
      let submissionDone = false;

      while (!submissionDone) {
        submissionDone = true;
        jsonGetSolution = await agent.Assignments.checkTestCaseStatus({
          codeTokens: tokens,
        });
        jsonGetSolution = jsonGetSolution.data.data;
        jsonGetSolution.submissions.forEach((s, i) => {
          let output = '';
          if (
            s.status.description === 'In Queue' ||
            s.status.description === 'Processing'
          ) {
            submissionDone = false;
            output = `Submission Status: ${s.status.description}`;
          } else {
            if (s.status.description === 'Time Limit Exceeded') {
              output = `Error :${s.status.description}`;
            } else if (s.stdout) {
              output = s.stdout;
              console.log(JSON.stringify(output));
            } else if (s.stderr) {
              output = s.stderr;
              if (output.length > 100) {
                output = output.substring(0, 100);
                output += '\nTruncated Output';
              }
              output = `Error :${output}`;
            } else {
              output = s.compile_output;
              if (output && output.length > 100) {
                output = output.substring(0, 100);
                output += '\nTruncated Output';
              }
              output = `Error :${output}`;
            }
          }
          setOutput(output);
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading({ ...loading, generatingOutput: false });
    }
  };

  const saveTestCase = async () => {
    if (!boilerplateCode) {
      return toast.error('Please add boilerplate code');
    }
    if (!input || !output) {
      return toast.error('input and output are required');
    }
    if (!TaskId) {
      return toast.error('Task Id is required');
    }
    const validationResult = validateSQLInputFormat(input);
    if (!validationResult.isValid) {
      return toast.error(validationResult.message);
    }
    try {
      setLoading({ ...loading, saveTestCase: true });
      await agent.Assignments.addTestCase({
        solution: solutionCode,
        input: input?.trim() || '',
        output,
        AssignmentTaskId: TaskId,
        boilerPlateCode: boilerplateCode,
        isHidden,
      });

      toast.success('Test case added successfully');
      await getATask();

      setIsOpen(false);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading({ ...loading, saveTestCase: false });
    }
  };
  const updateTestCase = async () => {
    if (!boilerplateCode) {
      return toast.error('Please add boilerplate code');
    }
    if (!input || !output) {
      return toast.error('input and output are required');
    }
    if (!TaskId) {
      return toast.error('Task Id is required');
    }
    const validationResult = validateSQLInputFormat(input);
    if (!validationResult.isValid) {
      return toast.error(validationResult.message);
    }
    try {
      setLoading({ ...loading, saveTestCase: true });
      await agent.Assignments.updateTestCase({
        ...testCase,
        solution: solutionCode,
        input: input?.trim() || '',
        output,
        AssignmentTaskId: TaskId,
        boilerPlateCode: boilerplateCode,
        isHidden,
      });

      toast.success('Test case updated successfully');
      await getATask();
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setLoading({ ...loading, saveTestCase: false });
    }
  };
  const handleChangeTab = (value) => {
    if (value === 'output' && !solutionCode) {
      setSolutionCode(extractCodeToShowUser(boilerplateCode));
    }

    setSelectedTab(value);
  };

  return (
    <>
      <div className="w-fit">
        <button
          onClick={() => setIsOpen(true)}
          className="px-5 py-2 bg-blue-500 text-white rounded-md"
        >
          {isUpdate ? 'Edit' : 'Create Test Case'}
        </button>
      </div>
      {open && (
        <Dialog open={isOpen} maxWidth="lg" fullWidth>
          <Tabs value={selectedTab} className="w-full relative py-2 px-2">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-1 right-2"
            >
              <Close />
            </button>
            <TabsList className="grid w-[400px] grid-cols-2 relative">
              <TabsTrigger value="boilerplate" className="p-0 h-full">
                <div
                  onClick={() => handleChangeTab('boilerplate')}
                  className="w-full h-full flex justify-center items-center"
                >
                  Boiler plate code
                </div>
              </TabsTrigger>
              <TabsTrigger value="output" className="p-0 h-full">
                <div
                  onClick={() => handleChangeTab('output')}
                  className="w-full h-full flex justify-center items-center"
                >
                  Test case output
                </div>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="boilerplate">
              <div className="w-full px-2 py-2">
                <h3 className="py-1 font-medium text-xl mb-2">
                  {isUpdate ? 'Update Boiler Plate' : 'Add Boiler Plate'}
                </h3>
                <div className="w-full h-[60vh]">
                  <MonacoEditor
                    language="sql"
                    height="100%"
                    onChange={handleBoilerplateCodeChange}
                    value={boilerplateCode}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value="output">
              <div className="w-full px-2 py-2">
                <div className="flex justify-between my-1">
                  <h3 className="py-1 font-medium text-xl mb-2">
                    Test Case Output
                  </h3>
                  <button
                    className="border-2 border-solid px-2 py-1 rounded-md"
                    onClick={() =>
                      setSolutionCode(
                        solution || extractCodeToShowUser(boilerplateCode)
                      )
                    }
                  >
                    Reset code
                  </button>
                </div>
                <div className="w-full h-[60vh]">
                  <MonacoEditor
                    language="sql"
                    height="100%"
                    onChange={handleOutputCodeChange}
                    value={solutionCode}
                  />
                </div>

                <div className="flex w-full justify-between py-2">
                  <div className="w-[45%]">
                    <p className="my-2">Input</p>
                    <div className="w-full border-2 border-solid outline-none resize-none">
                      <textarea
                        onChange={(e) => setInput(e.target.value)}
                        rows={10}
                        className="w-full resize-none outline-none p-1"
                        value={input}
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-[45%]">
                    <p className="my-2">Output</p>
                    <div className="w-full border-2 border-solid ">
                      <textarea
                        rows={10}
                        readOnly
                        value={output}
                        className="w-full resize-none outline-none p-1"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4">
                  <button
                    className="bg-blue-500 text-white  py-2 w-[10rem] rounded-md"
                    onClick={generateTestCaseOutput}
                    disabled={loading.generatingOutput}
                  >
                    {loading.generatingOutput ? (
                      <CircularProgress color="white" size={15} />
                    ) : (
                      'Generate Output'
                    )}
                  </button>
                  <button
                    className="bg-green-500 text-white  w-[10rem] py-2 rounded-md"
                    onClick={() => {
                      isUpdate ? updateTestCase() : saveTestCase();
                    }}
                    disabled={loading.saveTestCase}
                  >
                    {loading.saveTestCase ? (
                      <CircularProgress color="white" size={15} />
                    ) : isUpdate ? (
                      'Update Test Case'
                    ) : (
                      'Save Test Case'
                    )}
                  </button>

                  <div className="flex gap-2 items-center">
                    <p className="font-semibold text-gray-600">Is Hidden : </p>
                    <Switch checked={isHidden} onCheckedChange={setIsHidden} />
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </Dialog>
      )}
    </>
  );
};

export default AddOrUpdateSQLTestCase;
